export const icon_names = [
  'HSFO',
  'ULSFO',
  'HSFO-ULSFO',
  'VLSFO',
  'HSFO-VLSFO',
  'ULSFO-VLSFO',
  'HSFO-ULSFO-VLSFO',
  'HSGO',
  'HSFO-HSGO',
  'HSGO-ULSFO',
  'HSFO-HSGO-ULSFO',
  'HSGO-VLSFO',
  'HSFO-HSGO-VLSFO',
  'HSGO-ULSFO-VLSFO',
  'HSFO-HSGO-ULSFO-VLSFO',
  'ULSGO',
  'HSFO-ULSGO',
  'ULSFO-ULSGO',
  'HSFO-ULSFO-ULSGO',
  'ULSGO-VLSFO',
  'HSFO-ULSGO-VLSFO',
  'ULSFO-ULSGO-VLSFO',
  'HSFO-ULSFO-ULSGO-VLSFO',
  'HSGO-ULSGO',
  'HSFO-HSGO-ULSGO',
  'HSGO-ULSFO-ULSGO',
  'HSFO-HSGO-ULSFO-ULSGO',
  'HSGO-ULSGO-VLSFO',
  'HSFO-HSGO-ULSGO-VLSFO',
  'HSGO-ULSFO-ULSGO-VLSFO',
  'HSFO-HSGO-ULSFO-ULSGO-VLSFO',
  'VLSGO',
  'HSFO-VLSGO',
  'ULSFO-VLSGO',
  'HSFO-ULSFO-VLSGO',
  'VLSFO-VLSGO',
  'HSFO-VLSFO-VLSGO',
  'ULSFO-VLSFO-VLSGO',
  'HSFO-ULSFO-VLSFO-VLSGO',
  'HSGO-VLSGO',
  'HSFO-HSGO-VLSGO',
  'HSGO-ULSFO-VLSGO',
  'HSFO-HSGO-ULSFO-VLSGO',
  'HSGO-VLSFO-VLSGO',
  'HSFO-HSGO-VLSFO-VLSGO',
  'HSGO-ULSFO-VLSFO-VLSGO',
  'HSFO-HSGO-ULSFO-VLSFO-VLSGO',
  'ULSGO-VLSGO',
  'HSFO-ULSGO-VLSGO',
  'ULSFO-ULSGO-VLSGO',
  'HSFO-ULSFO-ULSGO-VLSGO',
  'ULSGO-VLSFO-VLSGO',
  'HSFO-ULSGO-VLSFO-VLSGO',
  'ULSFO-ULSGO-VLSFO-VLSGO',
  'HSFO-ULSFO-ULSGO-VLSFO-VLSGO',
  'HSGO-ULSGO-VLSGO',
  'HSFO-HSGO-ULSGO-VLSGO',
  'HSGO-ULSFO-ULSGO-VLSGO',
  'HSFO-HSGO-ULSFO-ULSGO-VLSGO',
  'HSGO-ULSGO-VLSFO-VLSGO',
  'HSFO-HSGO-ULSGO-VLSFO-VLSGO',
  'HSGO-ULSFO-ULSGO-VLSFO-VLSGO',
  'HSFO-HSGO-ULSFO-ULSGO-VLSFO-VLSGO'
]
