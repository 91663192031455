import Vue from 'vue'
import App from '../App.vue'
import store from '../store/'
import BootstrapVue from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import "chartjs-plugin-annotation"
import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;


Vue.config.productionTip = false
Vue.use(BootstrapVue)

export const eventBus = new Vue({
    methods: {
        openSideToggle(){
            this.$emit('openSideToggle');
        },
        refreshSVG(width){
          this.$emit('updateFullSVG', width)
        }
    }
});

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
