<template>
  <div class="stickytop">
    <div class="table-responsive">
      <table class="table table-striped table-sm table-lrgmt">
        <thead>
          <tr>
            <th>Test parameter</th>
            <th>Unit</th>
            <th>ISO Limit</th>
            <th>Min</th>
            <th>Avg</th>
            <th>Max</th>
            <th class="limits_col">Results</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="line in table_data.table">
            <td>{{line.name}}</td>
            <td>{{line.unit}}</td>
            <td>{{line.limit}}</td>
            <td v-html="line.min"></td>
            <td class="avg" v-bind:data-id="line.id" v-html="line.avg"></td>
            <td v-html="line.max"></td>
            <td>
              <div class="badge-group" v-if="line.outcomes.show">
                <span class="badge badge-success">{{line.outcomes.green}} </span>
                <span class="badge badge-warning">{{line.outcomes.amber}} </span>
                <span class="badge badge-danger">{{line.outcomes.red}}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('single_port', {
      table_data: 'table_data'
    })
  },
};

</script>

<style lang="scss">

.badge-group {
  .badge {
    border-radius: 0;
    min-width: 25px;

    &:first-child {
      border-radius: 0.25rem 0 0 0.25rem;
    }

    &:last-child {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}

.limits_col {
  min-width: 105px;
}

</style>
