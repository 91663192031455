<template>
  <div id="app">
    <component v-bind:is="view"></component>
    <loading v-if="loading"></loading>
    <b-modal
      id="help-modal"
      v-if="!loading"
      v-show="!loading"
      ref="help_modal"
      title="Help"
      ok-variant=" btn-sm btn-info"
      scrollable
      ok-only
      ok-title="Close"
      size="lg"
    ><help></help></b-modal>
    <b-alert
      :variant="pdf_alert.class"
      :show="pdf_alert.seconds"
      dismissible
      class="alert-toast"
      @dismissed="pdf_alert.seconds = 0"
      fade
      v-html="pdf_alert.text"
    ></b-alert>

    <!-- <b-button v-b-modal.help-modal>Help</b-button>  -->
  </div>
</template>

<script>
import globe from './components/Map.vue'
import port from './components/Port.vue'
import comparison from './components/ComparisonPage.vue'
import loading from './components/Loading.vue'
import expired from './components/Expired.vue'
import welcome from './components/Welcome.vue'
import help from './components/Help.vue'
import { mapState } from 'vuex'

export default {
  name: 'app',
  components: {
    port,
    globe,
    comparison,
    loading,
    expired,
    welcome,
    help
  },
  computed: {
    ...mapState('options', ['view', 'loading', 'pdf_alert']),
  },
  beforeCreate() {
    if (this.$store.getters['auth/isLoggedIn']) {
      this.$store.dispatch('ports/getPortData')
      this.$store.dispatch('characteristics/getData')
      this.$store.dispatch('suppliers/getData')
      this.$store.dispatch('options/getSavedPorts')
    }
    else {
      this.$store.dispatch('options/set_view', 'welcome')
      this.$store.dispatch('options/set_loading', false)
    }
  }
}


</script>

<style>
  .modal-backdrop.show{
    background: #003c71;
    opacity: .85 !important;
  }

  .alert.alert-toast {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5000;
  }
</style>
