<template>
  <div>
    <div class="chart-scroll">
      <div class="chart-wrapper" :style="{width: wrapper_width}">
        <port-chart
          v-if="id != null"
          :id="id"
          :full-size="width"
          hide-selector
        >
        </port-chart>
      </div>
    </div>
    <div class="chart-axis">
      <svg id="chart-axis">
        <use xlink:href="#yaxis"></use>
      </svg>
    </div>
  </div>
</template>

<script>
import PortChart from './PortChart.vue'

export default {
  components: {
    PortChart
  },
  props: {
    id: {
      type: Number
    },
    width: {
      type: Number
    }
  },
  computed: {
    wrapper_width() {
      let w = this.width + 30
      return w + 'px'
    }
  }
}
</script>

<style lang="scss">
.chart-scroll {
  width: 100%;
  position: relative;
  overflow: auto;
}

.chart-axis {
  position: absolute;
  top: 77px;
  left: 2px;
  padding-left: 40px;
  height: 156px;
  background-color: #fff;
}
.chart-wrapper {

  .chart-container {
    height: 600px;
    display: block;
    padding: 0;
  }

  .legend {
    text-align: left;
  }

  .my-3 .row {
    box-shadow: none;
  }
}
</style>
