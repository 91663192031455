<template>
  <div class="comps">
    <div class="header  port-header">
      <b-button @click="set_view('globe')" class="btn btn-xl btn-secondary"><i class="fas fa-arrow-alt-circle-left"></i> Go back</b-button>
      <div><img src="./../assets/images/lr_logo.svg"  id="logo" /></div>
      <div class="row" style="position: relative; left: -18px;">
        <a href="https://s3.amazonaws.com/Neptune/stats_presentation/FOBAS-Stats-Presentation" target="_blank" style="text-decoration: none;">
          <button type="button" class="btn btn-primary">
            <i class="fas fa-question-circle"></i> Overview
          </button>
        </a> &nbsp;
        <b-button class="btn btn-primary"  v-b-modal.help-modal>
            <i class="fas fa-info-circle"></i> Help
        </b-button>
      </div>
    </div>
    <div class="port-view">
      <div class="container-fluid pt-1">
        <b-alert variant="warning" :show="errors.length > 0">
          <ul class="pl-3">
            <li v-for="error in errors">{{error}}</li>
          </ul>
          <p class="my-1">Maybe we could help? Please contact us.</p>
        </b-alert>
        <div class="row">
          <div class="col-xs-12 col-sm-3 flex">
            <div class="date-range">
              <div class="date">Date from<span> {{formatted_dates.from}}</span></div>
              <div class="date">Date to<span> {{formatted_dates.to}}</span></div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-9">
            <table class="table table-comparison">
              <thead>
                <tr>
                  <th></th>
                  <th>Port</th>
                  <th>Fuel</th>
                  <th>Grade</th>
                  <th>Supplier</th>
                  <th>Sample Count</th>
                  <th>Sample Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(group, index) in data">
                  <td><b-img blank width="20" height="20" :blank-color="colours[index]" /></td>
                  <td>{{group.data.port_name}}</td>
                  <td>{{group.data.fuel_type}}</td>
                  <td>{{group.data.grade}}</td>
                  <td>{{supplier_name(group.data.supplier)}}</td>
                  <td>{{group.samples.length}}</td>
                  <td>{{sample_percent(group.samples.length)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <comparison-chart v-for="c in charts" :id="c.id" :key="c.id"></comparison-chart>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <b-button @click="addChart" class="btn btn-xl btn-primary">Add new chart</b-button>
        </div>
        <div class="col text-right">
          <report-button />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import ComparisonChart from './ComparisonChart.vue'
  import moment from 'moment'
  import reportButton from './ReportButton'

  export default {
    components: {
      ComparisonChart,
      reportButton
    },
    computed: {
      ...mapState('comparison', [
        'data',
        'available_characteristics',
        'charts',
        'colours'
      ]),
      ...mapState('options', [
        'selected_fuel_type',
        'dates'
      ]),
      ...mapGetters('comparison', ['errors']),
      ...mapGetters('suppliers', ['supplier_name']),
      formatted_dates() {
        let new_dates = {}
        for (var date_type in this.dates) {
          // skip loop if the property is from prototype
          if (!this.dates.hasOwnProperty(date_type)) continue;

          new_dates[date_type] = moment(this.dates[date_type]).format('DD/MM/YYYY')
        }
        return new_dates
      },
      total_samples() {
        return this.data.map(d => d.samples.length).reduce((p, c) => p + c, 0)
      }
    },
    methods: {
      onClick() {
        this.chartCount++
      },
      ...mapActions('options', [
        'set_view'
      ]),
      ...mapActions('comparison', [
        'addChart',
      ]),
      sample_percent(count) {
        return ((count / this.total_samples) * 100).toFixed(2) + '%'
      }
    },
    beforeCreate() {
      this.$store.dispatch('comparison/getAllSamples')
      this.$store.dispatch('comparison/getOutcomes')
    }
  }
</script>

<style lang="scss">
  .port-view{
    margin-top: 60px;
  }
  .flex{
    display:flex;
  }
  .date-range{
    background: #f5f5f5;
    display: flex;
    width: 100%;
    padding: .5rem;
    margin: 0 0 5px 0;
    justify-content: space-between;
    .date{
      display: inline-block;
      span{
        font-weight: 700;
      }
    }
  }
  .table-comparison{
    margin-bottom: 0;
    thead{
      tr{
        th{
          padding: .2rem;
          background:#f7f7f7;
        }
      }
    }
    tbody{
      tr{
        td{
          padding: .2rem;
          font-size: .9rem;
        }
      }
    }
  }
  @media screen and (min-width: 500px) {
    .map-overlay .header {
      padding: 10px 20px;
    }
  }
  @media screen and (min-width:576px){
    .date-range{
      margin:0;
      flex-flow: column;
      justify-content: flex-start;
      .date{
        span{
          display: contents;
        }
      }
    }
  }
</style>
