import api from '../../api'

const state = {
  all_ports: [],
  map_fuel_types: ['HSFO', 'HSGO', 'ULSGO', 'VLSGO', 'ULSFO', 'VLSFO'],   // hide the others for now ['alerts', 'bulletins'],
  query: ''
}

const getters = {
  filtered_ports ( state ) {
    let fuel_types = state.map_fuel_types
    let filtered_ports = []

    fuel_types.forEach((type) => {
      filtered_ports = filtered_ports.concat(state.all_ports.filter( port => port[type] !== undefined && port[type] != null && port[type] != 0))
    })

    filtered_ports = [...new Set(filtered_ports)]

    if (state.query !== '') {
      filtered_ports = filtered_ports.filter((port) => port.nm.toLowerCase().includes(state.query.toLowerCase()))
    }
    return filtered_ports
  },
  port_by_id (state) {
    return (function (port_id) {
      return state.all_ports.filter(function(port) {
        return port.id == port_id
      })
    })
  },
  port_name (state) {
    return (function(port_id) {
      let this_port = state.all_ports.filter(port => port.id == port_id)[0]
      return this_port == undefined ? '' : this_port.name
    })
  }
}

const actions = {
  getPortData ({ commit, dispatch, rootState }) {
    dispatch('options/set_loading', true, {root: true})
    api.get('api/v1/ports', {params: {from: rootState.options.dates.from , to: rootState.options.dates.to }})
      .then(response => {
        commit('setPortData', response.data[2])

        // commit('changeDates', response.data[0], response.data[1])
        // doesn't work - need to find correct mutator etc
        // rootState.options.dates.from = response.data[0]
        // rootState.options.dates.to   = response.data[1]

        dispatch('options/set_loading', false, {root: true})
      })
  },
  addMapFuelType ({ commit }, fuel_type) {
    commit('addMapFuelType', fuel_type)
  },
  removeMapFuelType ({ commit }, fuel_type) {
    commit('removeMapFuelType', fuel_type)
  },
  updateSearchQuery ( { commit }, query) {
    commit('updateQuery', query)
  }
}

const mutations = {
  setPortData (state, ports) {
    state.all_ports = ports
  },
  addMapFuelType ( state, fuel_type ) {
    state.map_fuel_types.push(fuel_type)
  },
  removeMapFuelType ( state, fuel_type ) {
    let array = state.map_fuel_types
    const index = array.indexOf(fuel_type)

    if (index !== -1) {
      array.splice(index, 1)
    }
    state.map_fuel_types = array
  },
  updateQuery (state, query) {
    state.query = query
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
