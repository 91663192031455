<template>
  <div>
    <div v-if="!loading">
    <b-form-group :label-cols="4" label="Fuel Options" label-class="d-print-block">
      <b-form-select @change="update_band_and_grade" :value="selected_band_and_grade" :readonly="available_band_grade_options[0].length == 1">
        <optgroup v-for="[band,grade_info] of available_band_grade_options[1]" :label="band">
          <option v-for="[grade, info] of grade_info" :value="info.id">{{info.text}}</option>
        </optgroup>
      </b-form-select>
    </b-form-group>
    <b-form-group :label-cols="4" label="Supplier">
      <b-form-select @change="update_supplier" :value="selected_supplier">
        <option :value="null">All</option>
        <option v-for="supplier in available_suppliers" :value="supplier.id">{{supplier.name}}</option>
      </b-form-select>
    </b-form-group>
    </div>
    <div v-else>
      Loading
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

export default {
  computed: {
    ...mapGetters('single_port', [
      'available_band_grade_options',
      'available_suppliers',
      'filtered_data'
    ]),
    ...mapState('options', [
      'fuel_types',
      'selected_band_and_grade',
      'selected_supplier',
      'dates',
      'loading'
    ]),
    ...mapGetters('single_port', ['port_data', 'outcomes_data']),
    ...mapState('single_port', [
      'available_characteristics',
      'charts'
    ])
  },
  methods: {
    ...mapActions('options', [
      'update_band_and_grade',
      'update_supplier',
    ]),
  }
};

</script>

<style lang="scss">

</style>
