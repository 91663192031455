<template>
  <div class="container-fluid my-3">
    <div class="port-header-spacer" v-if="index%2 == 0"></div>
    <b-row>
      <div class="filter" v-if="!hideSelector">
        <b-form-select :value="selected_char" @change="update_char" class="mb-3">
          <option v-for="(value, key) in characteristics" :value="value.id">{{value.name}}</option>
        </b-form-select>
        <div v-if="charts.length > 1" v-on:click="removeChart(id)" class="remove-chart"><i class="fas fa-times"></i></div>
        <div style="float: right;" v-if="this.selected_data.large_set">
          <button class="btn btn-secondary btn-md" @click="openModal"> See larger </button>
        </div>
      </div>
      <ul class="legend" v-if="selected_data.average || selected_data.limits">
        <li v-if="selected_data.average"><span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#3b8ede" /></span>Average <span v-html="selected_data.average_txt"></span></li>
        <span v-if="selected_data.limits">
          <li v-if="selected_data.limits.red" v-bind:title="'Limit is ' + selected_data.limits.red">
            <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#dc3545" /></span>Red ISO 8127{{ selected_char == 33 ? '/ECA' : ''}} Limit + Confidence {{selected_data.limits.red_txt}}
          </li>
          <li v-if="selected_data.limits.amber" v-bind:title="'Limit is ' + selected_data.limits.amber">
            <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#ffc107" /></span>Amber ISO 8127{{ selected_char == 33 ? '/ECA' : ''}} Limit {{selected_data.limits.amber_txt}}
          </li>
          <li v-if="selected_data.limits.bottom" v-bind:title="'Limit is ' + selected_data.limits.bottom">
            <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#87cefa" /></span>Lower Limit {{selected_data.limits.bottom_txt}}
          </li>
        </span>
      </ul>
      <div class="port-chart-wrapper" style="padding: 0 20px; width: 100%">
        <div class="port-chart">
          <svg preserveAspectRatio="none" :style="{width: svg_width, height: '100%'}"> </svg>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PortBarChart from './PortBarChart.vue'
import Chart from 'chart.js'
import util from '../util.js'
import svg_chart from '../svg_chart'
import {eventBus} from '../packs/stats'


export default {
  components: { PortBarChart },

  props: {
    id: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    fullSize: {
      default: null,
      type: Number
    },
    hideSelector: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    ...mapActions('single_port', [
      'updateChar',
      'removeChart'
    ]),

    updateSVG(width = null) {
      let svg = this.$el.getElementsByTagName('svg')[0]
      svg_chart.draw_svg_chart(svg, {bottom: this.selected_data.bottom,
                                     unit_label: this.selected_data.unit_label,
                                     rounding_dp: this.selected_data.rounding_dp,
                                     datasets: [this.selected_data],
                                     width: width
                                   })
    },

    update_full_width() {
      let self = this
      Chart.helpers.each(Chart.instances,(instance) => {
        if(instance.canvas.id == "bar_chart_full"){
          instance.width = self.full_width
          instance.canvas.width = self.full_width
          instance.canvas.style.width = self.full_width + 'px'
          instance.update()
        }
      })

    },

    update_char(value) {
      let payload = {
        id: this.id,
        selected_char: value
      }
      this.updateChar(payload)
    },

    get_animations() {
      alert("ever used?")
      if (this.fullSize == null) return {}

      return {
        onComplete(animation) {
          let myLiveChart = animation.chart
          let sourceCanvas = myLiveChart.canvas;
          let copyWidth = myLiveChart.scales['y-axis-0'].width;
          let copyHeight = myLiveChart.scales['y-axis-0'].height + myLiveChart.scales['y-axis-0'].top + 5;
          let elem = document.getElementById('chart-axis')
          let targetCtx = elem.getContext("2d");
          targetCtx.canvas.width = copyWidth;
          targetCtx.canvas.height = copyHeight
          targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
          elem.parentNode.style.height = copyHeight+'px'
        }
      }
    },

    openModal() {
      this.$emit('show_modal', {'id': this.id, 'width': this.full_width })
    }
  },

  computed: {
    ...mapGetters('single_port', [
      'chart_data',
      'chart_data_count',
      'fvmm_for'
    ]),
    ...mapGetters('characteristics', [ 'char_limits', 'y_ticks', 'char_info', 'chart_limits' ]),   // y_ticks?
    ...mapState('single_port', [
      'charts',
      'outcomes'
    ]),
    ...mapState('single_port', {
      characteristics: 'available_characteristics',
    }),
    ...mapState('comparison', ['colours']),

    svg_width() {
      if (this.fullSize) {
        return this.fullSize + 'px'
      }
      else {
        return '100%'
      }
    },


    limits() {
      // remove completely?
    },

    selected_data() {
      let info = this.chart_data(this.selected_char)

      if (info == null) {
        return {}
      } else {
        let colour = this.colours[this.id % 4]
        let char_info = this.char_info(this.selected_char)
        let data_average = util.avg(info.map(o => o[1]))

        // set lowest y-value for graph, put it slightly below lowest
        // might want to take limits into account as well?
        let min_data_val = Math.min(...info.map(i => i[1]))
        let bottom = min_data_val // let chart do the rest (ignore the fvmm remap here)

        let fvmm = this.fvmm_for(this.selected_char)

        let lines = [{'y': fvmm.round_fn(fvmm.data_val(data_average)), 'color': '#3b8ede' }]

        // assume limit numbers don't need mapping
        let the_limits = this.chart_limits(this.selected_char)
        if (the_limits) {
          if (the_limits.red)    { 
            lines.push({'y': the_limits.red,    'color': '#dc3545'})
            the_limits.red_txt = fvmm.report_val(the_limits.red)
          }
          if (the_limits.amber)  { 
            lines.push({'y': the_limits.amber,  'color': '#ffc107'}) 
            the_limits.amber_txt = fvmm.report_val(the_limits.amber)
          }
          if (the_limits.bottom) { 
            lines.push({'y': the_limits.bottom, 'color': '#87cefa'}) 
            the_limits.bottom_txt = fvmm.report_val(the_limits.bottom)
          }
        }

        let data = { colour: colour,
                     bottom: bottom,
                     unit_label: char_info.unit,
                     rounding_dp: fvmm.rounding_dp,
                     limits: the_limits,
                     lines: lines,
                     data: info.map(x => [x[0], fvmm.data_val(x[1])]),
                     average: fvmm.round_fn(fvmm.data_val(data_average)),  // note: rounded
                     average_txt: fvmm.report_val(data_average),
                     'large_set': info.length > 150  // arbitrary limit, could be changed
                   }
        return data
      }
    },

    selected_char() {
      return this.charts.filter(c => c.id == this.id)[0].selected_char
    },
    chart_ref() {
      let ref = "bar_chart_"+this.id
      if (this.fullSize) ref = "bar_chart_full"
      return ref
    },
    full_width() {
      // estimate for 3px per pt, allow 20% for y-axis space (hence 80% usable)
      let chart_width = this.chart_data_count(this.selected_char) * 3 / 0.80
      return Math.max(chart_width, (window.innerWidth - 94))
    }
  },
  watch: {
    selected_data(newData, oldData) {
      // Update chart when new data is set
      // console.log(newData)
      this.updateSVG()
    }
  },
  mounted() {
    // Show data on newly added chart
    this.updateSVG()
    eventBus.$on('updateFullSVG', (width) => {if (this.fullSize) this.updateSVG(width)});
  }

};

</script>

<style lang="scss">
.charts{
  .container-fluid.my-3{
    padding-left: 0;
    padding-right: 0;
    margin-top: 0 !important;
    .row{
      margin-top: 0 !important;
    }
  }
}

.remove-chart {
  color: #fff;
  float: right;
  font-size: 1.6em;
  padding-right: 10px;
  padding-left: 10px;

  .fas {
    cursor: pointer;
  }
}

.legend {
  list-style-type: none;
  padding: 0;
  text-align: center;
  width: 100%;

  li {
    display: inline-block;
    font-size: 0.8rem;

    span {
      display: inline-block;
      min-width: 30px;
      min-height: 10px;
    }
  }

  img {
    vertical-align: baseline;
  }
}
.chart-container{
  width: 100%;
  padding: 15px;
  #bar-chart{
    width: 100% !important;
    height: auto !important;
  }
}


</style>
