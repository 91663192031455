const sum = (xs) => xs.reduce((x,y) => x + y, 0)

const avg = (xs) => xs.length == 0 ? null : sum(xs) / xs.length 

// from https://stackoverflow.com/a/24782004
// breaks an array into chunks of given size
const chunk = function(arr, chunkSize) {
  var R = [];
  for (var i=0,len=arr.length; i<len; i+=chunkSize)
    R.push(arr.slice(i,i+chunkSize));
  return R;
};


// fetching the X%th item in an array, assumed sorted
// 0-max_index mapped to 0-1
function percentile(sorted_vs, pct) {
  let lx = sorted_vs.length - 1
  let rx = lx * pct / 100.0
  let lb = Math.floor(rx)
  let fr = rx - lb
  if (fr <= 0) {
    return sorted_vs[lb]
  } else {
    let ub = lb + 1
    return sorted_vs[lb] * (1 - fr) + sorted_vs[ub] * fr
  }
}


export default {
  namespaced: true,
  sum, 
  avg,
  chunk,
  percentile
}
