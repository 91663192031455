<template>
  <div class="container-fluid my-3">
    <b-row>
      <div class="filter">
        <b-form-select :value="selected_char" @change="update_char" class="mb-3">
          <option v-for="(value, key) in characteristics" :value="value.id">{{value.name}}</option>
        </b-form-select>
        <div class="remove-chart" v-if="charts.length > 1" @click="removeChart(id)"><i class="fas fa-times"></i></div>
      </div>
      <b-col cols="12" lg="6">
      <ul class="legend" v-if="key_information">
        <li v-if="key_information.average_txt">
          <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#3b8ede" /></span>
          Average <span v-html="key_information.average_txt"></span>
        </li>
        <li v-if="key_information.red_txt">
          <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#dc3545" /></span>
          Red ISO 8127{{ selected_char == 33 ? '/ECA' : ''}} Limit + Confidence <span v-html="key_information.red_txt"></span>
        </li>
        <li v-if="key_information.amber_txt">
          <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#ffc107" /></span>
          Amber ISO 8127{{ selected_char == 33 ? '/ECA' : ''}} Limit <span v-html="key_information.amber_txt"></span> 
        </li>
        <li v-if="key_information.bottom_txt">
          <span class="mx-1"><b-img blank width="20" height="10" class="mx-0 px-0" blank-color="#87cefa" /></span>
          Lower Limit <span v-html="key_information.bottom_txt"></span>
        </li>
      </ul>
        <div style="width: 100%; height: 400px;">
          <svg preserveAspectRatio="none" :style="{width: '100%', height: '100%'}"> </svg>
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <port-line-chart
          ref=line_chart
          v-on:chart:update="update_labels"
          v-on:chart:render="update_labels"
          :chart-data=selected_line_data
        ></port-line-chart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PortLineChart from './PortLineChart.vue'
import svg_chart from '../svg_chart'

export default {
  components: { PortLineChart },

  props: {
    id: Number
  },

  computed: {
    ...mapGetters('characteristics', ['char_limits', 'y_ticks']),
    ...mapGetters('comparison', [
      'chart_data',
      'histogram_data',
    ]),
    ...mapState('comparison', {
      characteristics: 'available_characteristics',
      charts: 'charts',
      outcomes: 'outcomes'
    }),
    hist_data() {
      return this.histogram_data(this.selected_char)
    },
    selected_line_data() {
      return this.hist_data.data
    },
    bar_data() {
      return this.chart_data(this.selected_char)
    },
    selected_bar_data() {
      return this.bar_data.data
    },
    selected_bar_limits() {
      return this.bar_data.lines
    },
    min_value() {
      return this.hist_data.min
    },
    max_value() {
      return this.hist_data.max
    },
    min_y() {
      return this.bar_data.min
    },
    max_y() {
      return this.bar_data.max
    },
    step_size() {
      return this.hist_data.step_size
    },
    bar_thickness() {
      return this.bar_data.bar_count
    },

    key_information() {
      let info = this.bar_data.data
      let out = {}

      let uniq = xs => [... new Set(xs)]
      let is_uniq = xs => uniq(xs).length == 1

      out.averages = info.map(x => [x.colour, x.average_txt])  // blue lines
      out.reds     = info.filter(x => x.limits && x.limits.red_txt).map(x => [x.colour, x.limits.red_txt])
      out.ambers   = info.filter(x => x.limits && x.limits.amber_txt).map(x => [x.colour, x.limits.amber_txt])
      out.bottoms  = info.filter(x => x.limits && x.limits.bottom_txt).map(x => [x.colour, x.limits.bottom_txt])

      let blot_style = c => "min-width: 8px; min-height: 8px; height: 8px; position: relative; top: 5px; background-color: " + c + ";"
      let col_and_value = xs => xs.map(x => "<span style='" + blot_style(x[0]) + "'> &nbsp; </span> " + x[1]).
                                reduce((x,y) => x + "&nbsp;" + y)

      // TODO compress to loop
      if (out.reds.length > 0) {
        if (is_uniq(out.reds.map(x => x[1]))) {
          out.red_txt = out.reds[0][1]
        } else {
          out.red_txt = "(" + col_and_value(out.reds) + ")"
        } 
      }
      if (out.ambers.length > 0) {
        if (is_uniq(out.ambers.map(x => x[1]))) {
          out.amber_txt = out.ambers[0][1]
        } else {
          out.amber_txt = "(" + col_and_value(out.ambers) + ")"
        } 
      }
      if (out.bottoms.length > 0) {
        if (is_uniq(out.bottoms.map(x => x[1]))) {
          out.bottom_txt = out.bottoms[0][1]
        } else {
          out.bottom_txt = "(" + col_and_value(out.bottoms) + ")"
        } 
      }
      if (out.averages.length > 0) {
        if (is_uniq(out.averages.map(x => x[1]))) {
          out.average_txt = out.averages[0][1]
        } else {
          out.average_txt = "(" + col_and_value(out.averages) + ")"
        } 
      }
      return out
    },

    selected_char() {
      return this.charts.filter(c => c.id == this.id)[0].selected_char
    },
    chart_ref() {
      return "bar_chart_"+this.id
    }
  },
  methods: {
    update_labels() {
      let chart = this.$refs.line_chart.$data._chart
      let ticks = chart.options.scales.xAxes[1].ticks

      ticks.max = this.max_value
      ticks.min = this.min_value
      ticks.stepSize = this.step_size

      chart.update()
    },
    update_limits() {
      let self = this
      Chart.helpers.each(Chart.instances,(instance) => {
        if(self.chart_ref == instance.canvas.id){
          instance.options.horizontalLine = this.selected_bar_limits
          instance.options.scales.yAxes[0].ticks = this.get_y_ticks()
        }
      })
      let chart = this.$refs[this.chart_ref].$data._chart
      chart.update()
    },
    update_char(value) {
      let payload = {
        id: this.id,
        selected_char: value
      }
      this.updateChar(payload)
    },
    get_y_ticks() {
      return this.y_ticks(this.selected_char, this.min_y, this.max_y)
    },
    ...mapActions('comparison', [
      'updateChar',
      'removeChart'
    ]),
    updateSVG() {
      let svg = this.$el.getElementsByTagName('svg')[0]
      svg_chart.draw_svg_chart(svg, { bottom: this.bar_data.bottom, 
                                      unit_label: this.bar_data.unit_label, 
                                      rounding_dp: this.bar_data.rounding_dp, 
                                      datasets: this.bar_data.data })
    }
  },
  watch: {
    bar_data(newData, oldData) {
      this.updateSVG()
    }
  },
  mounted() {
    this.updateSVG();
  }
};

</script>

<style>
  .my-3 .row{
    border-radius: .25rem;
    margin: 15px 0;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    background: #fff;
  }
</style>
