<template>
  <div class="ports">
    <div class="header port-header">
      <b-button @click="set_view('globe')" class="btn btn-xl btn-secondary d-print-none"><i class="fas fa-arrow-alt-circle-left"></i> Go back</b-button>
      <div><img src="./../assets/images/lr_logo.svg"  id="logo" /></div>
      <div class="row d-print-none" style="position: relative; left: -18px;">
        <a href="https://s3.amazonaws.com/Neptune/stats_presentation/FOBAS-Stats-Presentation" target="_blank" style="text-decoration: none;">
          <button type="button" class="btn btn-primary">
            <i class="fas fa-question-circle"></i> Overview
          </button>
        </a> &nbsp;
        <b-button class="btn-primary"  v-b-modal.help-modal>
          <i class="fas fa-info-circle"></i> Help
        </b-button>
      </div>
    </div>
    <div class="port-view">
      <b-container fluid>
        <b-alert variant="warning" :show="filtered_data.length == 0 && outcomes_loading == false">
          <p class="my-1 text-center">There is no data available for the filter selection made. Maybe we could help? Please contact us.</p>
        </b-alert>
        <b-row class="my-3">
          <b-col cols="12" xl="3">
            <div class="sticky-top">
              <div class="card filter-card"  v-bind:class="{ active: !active }">
                <div class="card-header">
                  <div>
                    <h1>{{port_data.nm}}</h1>
                  </div>
                  <div class="toggler d-print-none"  v-on:click="active = !active"><i class="fas fa-angle-up"  v-bind:class="{ active: !active }"></i><i class="fas fa-angle-down"  v-bind:class="{ active: active }"></i></div>
                </div>
                <div class="card-body card-body-port">
                  <b-row>
                    <b-col cols="12" lg="9" xl="12" class="port-filter">
                      <b-form-group :label-cols="4" label="Date From" label-class="d-print-block">
                        <b-form-input :value="dates.from.format('DD/MM/YYYY')" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group :label-cols="4" label="Date To" label-class="d-print-block">
                        <b-form-input :value="dates.to.format('DD/MM/YYYY')" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group :label-cols="4" label="Fuel Options" label-class="d-print-block">
                        <b-form-select @change="update_band_and_grade" :value="selected_band_and_grade" :readonly="available_band_grade_options[0].length == 1">
                          <optgroup v-for="[band,grade_info] of available_band_grade_options[1]" :label="band">
                            <option v-for="[grade, info] of grade_info" :value="info.id">{{info.text}}</option>
                          </optgroup>
                        </b-form-select>
                      </b-form-group>
                      <b-form-group :label-cols="4" label="Supplier" label-class="d-print-block">
                        <b-form-select @change="update_supplier" :value="selected_supplier">
                          <option :value="null">All</option>
                          <option v-for="supplier in available_suppliers" :value="supplier.id">{{supplier.name}}</option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="3" xl="12" class="outcomes">
                      <h3>Overall Outcomes</h3>
                      <loading v-if="outcomes_loading" :fullscreen="false"></loading>
                      <div v-else>
                        <span style="color: #cc1e4f;"> Red   {{table_data.overall.red}}   </span><br>
                        <span style="color: #f6b436;"> Amber {{table_data.overall.amber}} </span><br>
                        <span style="color: #71d54c;"> Green {{table_data.overall.green}} </span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="card-footer d-print-none">
                  <div class="downloads">
                    <b-button size="sm" type="csv" @click="getCsv" :disabled="csv_loading">
                      <span v-if="csv_loading"><i class="fas fa-spinner fa-spin"></i> Loading</span>
                      <span v-else>Download CSV</span>
                    </b-button>
                    <b-button size="sm" type="excel" @click="getExcel" :disabled="xlsx_loading">
                      <span v-if="xlsx_loading"><i class="fas fa-spinner fa-spin"></i> Loading</span>
                      <span v-else>Download xlsx</span>
                    </b-button>
                  </div>
                  <div class="add-remove">
                    <b-button size="sm" class="btn-danger" @click="remove_from_comparison(selection_index)" v-if="selection_in_comparison">Remove from my ports</b-button>
                    <b-button size="sm" class="btn-success" @click="add_to_comparison" v-else>Add to my ports</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" lg="7" xl="5" class="table-col">
            <div class="table-wrapper">
              <port-table></port-table>
              <p class="mt-3 small">
                  The data presented in this table is made up of individual results. Based on this premise, it
                  should be noted that the ratio of green, amber, and red results will not necessarily reflect
                  the per-sample outcomes.
                  It is likely that some samples have multiple parameters which are off specification.
                  <span> Pour point limits are based on the <em>Winter</em> values. </span>
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="5" xl="4" class="charts">
            <div ref="charts">
              <port-chart v-for="(c, index) in charts" :id="c.id" :key="c.id" :index="index" v-on:show_modal="openModal"></port-chart>
            </div>
            <b-button @click="addChart" v-if="charts.length < 4" class="btn btn-xl btn-primary d-print-none">Add new chart</b-button>
            <p class="mt-3 small d-print-none">
                If you wish to see more detail for larger datasets, you can download the data for your selection in CSV or Excel formats.
                You can also click on the "See larger" button to open a new window that shows the full data on a much wider chart.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-modal
      v-model="show_modal"
      size="full"
      :title="chart_title"
      ok-only
      ok-title = 'Close'
    >
      <full-chart
        :id="full_chart_id"
        :width="full_chart_width"
      ></full-chart>
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import axios from 'axios';
import PortTable from './PortTable.vue'
import PortChart from './PortChart.vue'
import PortPieChart from './PortPieChart.vue'
import FullChart from './FullChart.vue'
import Loading from './Loading.vue'
import {eventBus} from '../packs/stats'


axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

export default {
  components: {
    PortTable, PortChart, PortPieChart, Loading, FullChart
  },
  data() {
    return {
      active: false,
      show_modal: false,
      full_chart_id: null,
      full_chart_width: (window.innerWidth - 64),
      csv_loading: false,
      xlsx_loading: false
    }
  },
  computed: {
    ...mapGetters('suppliers', ['supplier_name']),
    ...mapGetters('characteristics', ['char_name']),
    ...mapGetters('single_port', [
      'available_band_grade_options',
      'available_fuel_types',
      'available_grades',
      'available_suppliers',
      'filtered_data',
      'filtered_limits',
      'table_data',
      'port_data',
      //'outcomes_data'
    ]),
    ...mapState('options', [
      'fuel_types',
      'selected_port',
      'selected_band_and_grade',
      'selected_fuel_type',
      'selected_grade',
      'selected_supplier',
      'dates',
      'comparison_ports'
    ]),
    ...mapState('single_port', [
      'available_characteristics',
      'charts',
      'outcomes_loading'
    ]),

    selection_index() {
      return this.comparison_ports.findIndex(port => port.port_id == this.selected_port && port.supplier == this.selected_supplier && port.grade == this.selected_grade)
    },
    selection_in_comparison() {
      return this.selection_index != -1
    },
    chart_title() {
      let title = [this.port_data.nm, this.selected_fuel_type, this.selected_grade, this.supplier_name(this.selected_supplier)]
      if (this.full_chart_id != null) {
        let char_id = this.charts.filter(c => c.id == this.full_chart_id)[0].selected_char
        title.push(this.char_name(char_id))
      }
      return title.join(' - ')
    }
  },
  methods: {
    onClick() {
      this.chartCount++
    },
    ...mapActions('options', [
      'update_band_and_grade',
      'update_supplier',
      'set_view',
      'add_to_comparison',
      'remove_from_comparison'
    ]),
    ...mapActions('single_port', ['addChart']),
    getCsv(e) {
      e.preventDefault();
      if (this.csv_loading == true) return
      this.getFile('csv')
    },
    getExcel(e) {
      e.preventDefault()
      if (this.xlsx_loading == true) return
      this.getFile('excel')
    },
    getFile(type) {
      let self = this
      let output = type.toLowerCase() == 'csv' ? 'data_val' : 'report_val'
      let mapping = this.available_characteristics.
                    map(c => [c.id, c.fv_mm(this.selected_fuel_type)[output]]).
                    reduce(function (o,v) { o[v[0]] = v[1]; return o }, {})

      // override CSV output for Appearance
      let appearance = this.available_characteristics.find(c => c.id == 36)
      if (appearance) {
        mapping[appearance.id] = appearance.fv_mm(this.selected_fuel_type)['report_val']
      }

      let export_values = function(vs) {
                            let ws = {}
                            for(const [k,v] of Object.entries(vs)) {
                              ws[k] = mapping[k](v)
                            }
                            return ws
                          }

      let data = this.filtered_data.map(d => {
        return {
          i: d.i,
          p: this.supplier_name(d.p),
          d: d.d,
          g: d.g,
          f: d.f,
          v: export_values(d.v)
        }
      })
      type == 'csv' ? self.csv_loading = true : self.xlsx_loading = true
      axios({
        url: '/reports/'+type,
        method: 'POST',
        data: {
          data: data,
          limits: this.filtered_limits,
          chars: this.available_characteristics,
          port_name: this.port_data.nm
        },
        responseType: 'blob', // important
        headers: { 'Accept': 'application/vnd.ms-excel' }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        let filename = this.port_data.nm + "_samples"
        switch(type) {
          case 'csv':
            filename += '.csv'
            break
          case 'excel':
            filename += '.xlsx'
            break
        }

        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove()
        type == 'csv' ? self.csv_loading = false : self.xlsx_loading = false
      });
    },
    openModal({id, width}) {
      this.full_chart_id = id
      this.full_chart_width = width
      this.show_modal = true
      eventBus.refreshSVG(width)
    }
  },
  beforeCreate() {
    this.$store.dispatch('single_port/getAllSamples')
  }
};

</script>

<style lang="scss">
  .port-header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
  }
  #app .container-fluid:first-child{
    margin-top: 60px;
  }
  .filter-card {
    border: none !important;
    margin-bottom: 0 !important;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, .1); */
    .card-header {
      justify-content: space-between;
      display: flex;
      align-items: center;
      border-radius: 0 !important;
      max-height: 58px;
      padding: .75rem 1rem;
      h1 {
        font-size: 1.8rem !important;
      }
    }
    .card-header .fas {
      display: none;
      font-size: 1.8rem;
    }
    .card-header .fas.active {
      display: block !important;
    }
    .card-body, .card-footer{
      display:none;
    }
    .card-footer{
      >div {
        display:inline;
        .btn{
          margin-bottom: 5px;
        }
      }
    }
  }
  .filter-card.active{
    .card-body, .card-footer{
      display: block;
    }

  }
    .port-view .toggler {
      display: block;
      cursor: pointer;
      padding: 20px;
    }



  @media screen and (min-width: 416px) and (max-width: 1200px){
    .filter-card .card-footer > div.add-remove {
      float: right;
    }
  }
  @media screen and (min-width: 1200px){
    .port-view .toggler {
      display: none;
    }
  }
  @media screen and (min-width: 1660px){
    .filter-card .card-footer > div.add-remove {
      float: right;
    }
  }

  .modal-body {
    overflow: auto;
  }

  .modal .modal-full {
    margin-left: 15px;
    max-width: 100%;
    top: 380px;
  }

  .table-wrapper {
    position: sticky;
    top: 0;
  }


</style>
