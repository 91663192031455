import api from '../../api'
import fuel_bands from './fuel_bands'
import characteristics from './characteristics'
import util from '../../util'
import moment from 'moment'

// initial state
const state = {
  sample_data: [],
  available_characteristics: [],
  charts : [{
    id: 0,
    selected_char: 4
  }],
  outcomes: [],
  outcomes_loading: true
}

// getters
const getters = {
  filtered_data(state, getters, rootState) {
    let filtered = port_helpers.filter_by(state.sample_data, 'f', rootState.options.selected_fuel_type)

    if (rootState.options.selected_grade != null) {
      filtered = port_helpers.filter_by(filtered, 'g', rootState.options.selected_grade)
    }
    if (rootState.options.selected_supplier != null) {
      filtered = port_helpers.filter_by(filtered, 'p', rootState.options.selected_supplier, rootState.options.selected_supplier == 1049)
    }
    return filtered
  },

  table_data(state, getters, rootState, rootGetters) {
    let data = {}
    if (state.sample_data === undefined) {
      return data
    }
    let filtered_limits = getters.filtered_limits
    let chop_appearance = (v) => v.replace('Clear & Bright', 'C & B')

    let worst_outcomes = { 'red': new Set(), 'amber': new Set() }

    rootState.characteristics.data.forEach(char => {
      let samples = filter_by_char(getters.filtered_data, char.id);
      if(samples.length > 0) {
        let char_values = samples.map(({v}) => v[char.id])

        let outcome_data = {
          show: false
        }

        let limit_info = rootGetters['characteristics/char_limits'](char.id)

        if (limit_info == null) {
          // nothing to draw

        } else if (limit_info.special && char.id != 34) {
          // special cases ?
          // ULO ?
            // outcome_data.show  = true
            outcome_data.green = 999
            outcome_data.amber = 9999
            outcome_data.red   = 99999

        } else {
          // usual counting
          let char_outcomes = filtered_limits.filter( o => o.c == char.id)
          let amber_samples = char_outcomes.map(({v}) => v.a || []).reduce((x,y) => x.concat(y), [])
          let red_samples   = char_outcomes.map(({v}) => v.r || []).reduce((x,y) => x.concat(y), [])

          red_samples.  forEach(s => worst_outcomes.red.add(s))
          amber_samples.forEach(s => worst_outcomes.amber.add(s))

          let outcomes = {r: 0, a: 0, g: 0}
          outcomes['g'] = util.sum(char_outcomes.map(({v}) => v.g || 0))
          outcomes['a'] = amber_samples.length
          outcomes['r'] = red_samples.length
          outcomes['n'] = util.sum(char_outcomes.map(({v}) => v.n || 0))

// keep this so we can keep an eye on null results
if (outcomes['n'] > 0) {
  console.log("NON", char.id, outcomes['n'], outcomes)
}
          if (Object.keys(outcomes).length > 0) {
            outcome_data = {
              show: (outcomes.r > 0 || outcomes.a > 0 || outcomes.g > 0),
              red: outcomes.r,
              amber: outcomes.a,
              green: outcomes.g
            }
          }
        }

        let fvmm = char.fv_mm(rootState.options.selected_fuel_type)

        data[char.name] = {
          "id": char.id,
          "name": char.name,
          "unit": char.unit,
          "min": fvmm.report_val(Math.min(...char_values)),
          "max": fvmm.report_val(Math.max(...char_values)),
          "avg": fvmm.report_val(util.avg(char_values)),
          "outcomes": outcome_data,
          "limit_info": limit_info
        }

        if (char.name == "Appearance") {
          data[char.name].min   = chop_appearance(data[char.name].min)
          data[char.name].max   = chop_appearance(data[char.name].max)
          data[char.name].avg   = chop_appearance(data[char.name].avg)
        }

        if (limit_info == undefined || limit_info == null) {
          data[char.name].limit = ''

        } else {
          data[char.name].limit = fvmm.report_val(limit_info.limit) + " " + (limit_info.min_test ? "Min" : "Max")
          if (char.name == "Appearance") {
            data[char.name].limit = chop_appearance(data[char.name].limit)
          }
          if (limit_info.lower_bound) { 
            data[char.name].limit += ", " + fvmm.report_val(limit_info.lower_bound) + " Min"
          } 
  
        }
      }
    })

    // special case for ULO: binary split, 1 is bad, 0 is good, null is not-relevant
    let ulo_vals = getters.filtered_data.map(o => o.u).filter(v => v != null).sort()
    if (ulo_vals.length > 0 && rootState.options.selected_fuel_type.match('FO$')) {
      let outcome_data = { show: true }
      outcome_data.red   = ulo_vals.filter(v => v == 1).length
      outcome_data.amber = 0
      outcome_data.green = ulo_vals.filter(v => v == 0).length
      data["ULO"] = { "name": "ULO detected?", "outcomes": outcome_data }
    }

    // calculate the worst-case results, and stash it
    worst_outcomes.red.forEach(r => worst_outcomes.amber.delete(r))
    let red_count   = worst_outcomes.red.size
    let amber_count = worst_outcomes.amber.size
    let max_count   = getters.filtered_data.length
    let green_count = max_count - amber_count - red_count
    let overall     = { 'show': true, 'red': red_count, 'amber': amber_count, 'green': green_count }

    return { 'table' : data, 'overall': overall }
  },

  filtered_limits(state, getters, rootState) {
      let outcomes = state.outcomes.filter((outcome) => {
        let grade = rootState.options.selected_grade
        let supplier = rootState.options.selected_supplier
        let fuel_type = rootState.options.selected_fuel_type

        let match = (outcome.g == grade && outcome.f == fuel_type && outcome.v != null && outcome.v.l != undefined)
        if (supplier != null) {
          match = (match && outcome.p == supplier)
        }

        return match
      })
      return outcomes
  },

  fvmm_for(state, getters, rootState, rootGetters) {
    return function(char_id) {
      let char = rootGetters['characteristics/char_info'](char_id)
      return char.fv_mm(rootState.options.selected_fuel_type)
    }
  },

  chart_data(state, getters) {
    return function(char_id) {
      let samples = filter_by_char(getters.filtered_data, char_id);

      if (samples.length == 0) {
        return null

      } else {
        var points = samples.map(s => [moment(s.d).format('Do MMM'), s.v[char_id]])
        let data_average = util.avg(points.map(o => o[1]))    // use this?
        return points
      }
    }
  },

  chart_data_count(state, getters) {
    return function(char_id) {
      let samples = filter_by_char(getters.filtered_data, char_id);
      return samples.length
    }
  },

  port_data(state, getters, rootState, rootGetters) {
    const port_id = rootState.options.selected_port
    if (port_id == null) {
      return {}
    }
    else {
      return rootGetters['ports/port_by_id'](port_id)[0]
    }
  },

  available_band_grade_options(state) {
    let sample_order = function (x,y) {
      let by_band = fuel_bands.band_order(x.f, y.f)
      return by_band != 0 ? by_band : x.g.localeCompare(y.g)
    }
    let samples = state.sample_data.sort(sample_order)  // pre-sort by band and grade
 
    let opts = new Map()

    samples.map(function(s) {
      let x = opts.get(s.f) || opts.set(s.f, new Map()).get(s.f)
      x.set(s.g, 1 + (x.get(s.g) || x.set(s.g, 0).get(s.g)))
    })

    let combs = []

    opts.forEach(function(band_info,band) {
      band_info.forEach(function(count,grade) {
        combs.push({ id: band + ';' + grade,
                     band: band,
                     grade: grade,
                     text: band.substr(0, band.length - 2) + (band[0] == 'H' ? " " : "") + " - " + grade + " (" + count.toString() + ")" 

                   })
        band_info.set(grade, combs[combs.length - 1])
      })
    })

    // both, for convenience
    return [combs, opts]
  },

  available_fuel_types(state) {
    let samples = state.sample_data
    let fuel_types = samples.map(sample => sample.f)
    return [...new Set(fuel_types)].sort(fuel_bands.band_order)
  },

  available_grades(state, getters, rootState) {
    let samples = port_helpers.filter_by(state.sample_data, 'f', rootState.options.selected_fuel_type)
    let grades = samples.map(sample => sample.g)
    return [...new Set(grades)].sort()
  },

  available_suppliers(state, _getters, rootState) {
    if (rootState.suppliers.data == {}) return []
    let samples = port_helpers.filter_by(state.sample_data, 'f', rootState.options.selected_fuel_type)
    if(rootState.options.selected_grade !== null) {
      samples = port_helpers.filter_by(samples, 'g', rootState.options.selected_grade)
    }
    let supplier_ids = samples.map(sample => sample.p)
    let supplier_names = rootState.suppliers.data
    let suppliers = [...new Set(supplier_ids)].filter( x => x != null ).map(id => ({ name: supplier_names[id], id: id }))
    return suppliers.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
  }
}


// actions
const actions = {
  getAllSamples ({ commit, dispatch, getters, rootState }) {
    commit('setOutcomesLoading', true)
    dispatch('options/set_loading', true, {root: true})
    commit('setSamples', [])
    let port_id = rootState.options.selected_port
    api.get('api/v1/samples', {params: {
        port: port_id,
        from: rootState.options.dates.from,
        to: rootState.options.dates.to
      }}).then(response => {
        commit('setSamples', response.data)
        dispatch('updateCharacteristics')
        dispatch('options/set_loading', false, {root: true})

        let opts = rootState.options
        let bg_opts = getters.available_band_grade_options
        let bg_start = bg_opts[0].find(o => o.band == opts.selected_fuel_type && o.grade == opts.selected_grade)
        if (bg_start == null) { bg_start = bg_opts[0][0] }

        if (bg_start) { 
          dispatch('options/update_band_and_grade', bg_start.id, { root: true })
        }

        // unsure
        if (getters.available_suppliers.length == 1) {
          dispatch('options/update_supplier', getters.available_suppliers[0].id, { root: true })
        }

      })
      api.get('api/v1/limits', {params: {
        ports: [port_id],
        from: rootState.options.dates.from,
        to: rootState.options.dates.to
      }}).then((results) => {
        commit('setOutcomes', results.data)
        commit('setOutcomesLoading', false)
      })
  },
  updateCharacteristics({ commit, dispatch, getters, rootState }) {
    let char_ids = []
    getters.filtered_data.forEach(function(sample) {
      char_ids = char_ids.concat(Object.keys(sample.v))
    })
    char_ids = [...new Set(char_ids)]

    let with_samples = rootState.characteristics.data.map(char => {return char.id})
      .filter(key => char_ids.includes(key.toString()))

    let avail = rootState.characteristics.data.filter(char => with_samples.includes(char.id))

    commit('setCharacteristics', avail)
    if (avail.length > 0) {
      dispatch('updateCharts')
    }
  },
  addChart ({ state, commit }) {
    let ids      = state.charts.map((chart) => chart.id)
    let existing = state.charts.map((chart) => chart.selected_char)
    let unused   = state.available_characteristics.filter((char) => !existing.includes(char.id))
    let chart = {
      id: Math.max(...ids) + 1,
      selected_char: unused[0].id
    }
    commit('newChart', chart)
  },
  updateCharts ({ state, commit }) {
    state.charts.forEach((chart, index) => {
      let char_exists = state.available_characteristics.filter(c => c.id == chart.selected_char).length > 0
      let payload = {
        id: index,
        selected_char: char_exists ? chart.selected_char : state.available_characteristics[index].id
      }
      commit('updateCharacteristic', payload)
    })
  },
  updateChar ({ commit }, payload) {
// console.log("in updateChar", commit, this, payload)
    commit('updateCharacteristic', payload)
  },
  removeChart({ commit, state }, id) {
    let index = state.charts.findIndex((chart) => chart.id == id)
    commit('removeChart', index)
  }
}

// mutations
const mutations = {
  setSamples (state, samples) {
    state.sample_data = samples
    return samples
  },
  setOutcomes (state, outcomes) {
    state.outcomes = outcomes
  },
  setOutcomesLoading (state, flag) {
    state.outcomes_loading = flag
  },
  setCharacteristics (state, avail) {
    state.available_characteristics = avail
  },
  newChart (state, chart) {
    state.charts.push(chart)
  },
  updateCharacteristic (state, payload) {
    state.charts.filter(c => c.id == payload.id)[0].selected_char = payload.selected_char
  },
  removeChart (state, index) {
    let array = state.charts
    if (index !== -1) {
      array.splice(index, 1)
    }
    state.charts = array
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// helpers
function filter_by_char(samples, char_id) {
  return samples.filter(({v}) => v[char_id] !== undefined)
}

const port_helpers = {

  filter_by(samples, key, value, check_null = false) {
    if (check_null) {
      return samples.filter(sample => sample[key] == value || sample[key] == null)
    }
    else {
      return samples.filter((sample => sample[key] == value))
    }
  },
}
