<template lang="html">
  <b-dropdown id="report-dropdown" right offset="20,-60" text="Get report" variant="primary" ref="reportDropdown" @show="get_samples">
    <b-dropdown-text>Critical Params</b-dropdown-text>
    <b-dropdown-form>
    <div v-if="dropdown_chars().length == 0" style="background-color: bisque; padding: 3px 5px; margin-bottom: 3px;">
      No data available for selected ports
    </div>
    <div v-for="(cp, index) in critical_params">
      <b-form-select v-model="critical_params[index]" :options="dropdown_chars(cp)" class="mb-1" />
    </div>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text>Email</b-dropdown-text>
    <b-dropdown-form inline>
      <label class="sr-only" for="report-email">Email</label>
      <b-form-input placeholder="Email" type="email" :state="email_check" required id="report-email" v-model="user_email" />
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-form inline>
      <b-button variant="primary" :disabled="!validEmail(user_email)" class="mt-2" id="btn-download" @click="get_pdf">Send</b-button>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import api from '../api.js'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      user_email: '',
      critical_params: []
    }
  },
  props: {
    email_adddress: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    ...mapActions('options', ['set_pdf_alert']),
    ...mapActions('comparison', ['getAllSamples']),
    get_samples() {
      if (this.view == 'globe') {
        this.getAllSamples()
      }
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    get_pdf() {
      if (this.validEmail(this.user_email)) {
        api.post('reports/pdf', {
            ports: this.selected_comparison_ports,
            from: this.dates.from,
            to: this.dates.to,
            user: this.$store.state.auth.user,
            email: this.user_email,
            critical_params: this.modified_critical_params
        }).then((response) => {
          this.$refs.reportDropdown.hide(true)
          this.set_pdf_alert({
            class: 'success',
            text: response.data.message.split("\n").join("<br>"),  // assume sanitized
            seconds: 10
          })
        }).catch((err) => {
          this.set_pdf_alert({
            class: 'danger',
            text: 'Something went wrong',
            seconds: 10
          })
        })
      }
    }
  },
  computed: {
    ...mapState('options', [
      'dates',
      'selected_comparison_ports',
      'view'
    ]),
    ...mapState('comparison', ['available_characteristics']),
    ...mapState('characteristics', ['data']),
    ...mapGetters('characteristics', ['char_name']),
    email_check() {
      return this.validEmail(this.user_email)
    },
    dropdown_chars() {
      return function(char_id) {
        var chars = this.available_characteristics.filter(c => !this.critical_params.includes(c.id) || c.id == char_id)
        return chars.map(c => { return {value: c.id, text: c.name}})
      }
    },
    modified_critical_params() {
      return this.critical_params.map(cp => {
        var char_name = this.char_name(cp)
        return this.data.filter(d => d.name.toLowerCase() == char_name.toLowerCase()).map(d => d.id.toString())
      })
    }
  },
  beforeMount() {
    const fuel_re = /GO$/
    if (fuel_re.test(this.selected_comparison_ports[0].fuel_type)) {
      this.critical_params = [12, 8, 3, 33]
    }
    else {
      this.critical_params = [23, 8, 3, 33]
    }
  }
}
</script>

<style lang="css" >
#report-dropdown .dropdown-menu { 
  border: 1px solid rgba(0, 0, 0, 0.55);
  background-color: #bffded;
}
</style>

<style lang="css" scoped>
</style>
