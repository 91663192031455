const state = {
  authToken: localStorage.getItem('auth-token') || '',
  user: {}
}

const getters = {
  isLoggedIn: state => !!state.authToken,
}

const actions = {
  logout ( { dispatch, commit }, view ) {
    dispatch('options/set_view', view, { root: true })
    dispatch('options/set_loading', false, { root: true })
    if (localStorage.getItem('auth-token')) {
      localStorage.removeItem('auth-token')
      commit('deleteAuthToken')
    }
  },
  set_user ({ commit }, data) {
    commit('set_user', data)
  }
}

const mutations = {
  deleteAuthToken(state) {
    state.authToken = ''
  },
  set_user(state, data) {
    state.user = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}