import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import ports from './modules/ports'
import options from './modules/options'
import suppliers from './modules/suppliers'
import comparison from './modules/comparison'
import single_port from './modules/single_port'
import characteristics from './modules/characteristics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    ports,
    options,
    suppliers,
    comparison,
    single_port,
    characteristics,
  }
})
