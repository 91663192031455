var horizonalLinePlugin = {
  beforeDraw: function(chartInstance) {
    var yValue, startX, endX;
    var yScale = chartInstance.scales["y-axis-0"];
    var xScale = chartInstance.scales["x-axis-0"];
    var canvas = chartInstance.chart;
    var ctx = canvas.ctx;
    var index;
    var line;
    var style;

    if (chartInstance.options.horizontalLine) {
      for (index = 0; index < chartInstance.options.horizontalLine.length; index++) {
        line = chartInstance.options.horizontalLine[index];
        let datasets = chartInstance.data.datasets.length
        let t1 = xScale.getPixelForTick(0)
        let t2 = xScale.getPixelForTick(1)
        let offset = (t2 - t1)/2

        if (!line.style) {
          style = "rgba(169,169,169, .6)";
        } else {
          style = line.style;
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y);
        } else {
          yValue = 0;
        }

        if (line.start) {
          startX = xScale.getPixelForTick(line.start) - offset;
        } else {
          startX = canvas.chartArea.left;
        }

        if (line.end) {
          endX = xScale.getPixelForTick(line.end) - offset
        } else {
          endX = canvas.chartArea.right;
        }

        ctx.lineWidth = 2;

        if (yValue) {
          ctx.beginPath();
          ctx.moveTo(startX, yValue);
          ctx.lineTo(endX, yValue);
          ctx.strokeStyle = style;
          ctx.stroke();
        }

        if (line.text) {
          ctx.fillStyle = style;
          ctx.fillText(line.text, 0, yValue + ctx.lineWidth);
        }
      }
      return;
    }
  }
};
Chart.pluginService.register(horizonalLinePlugin);
