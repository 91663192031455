<template>
  <div class="loading-wrapper" v-bind:class="{ fullscreen: fullscreen }">
    <div class="spinner">
      <i class="fas fa-spinner fa-spin fa-3x"></i><br />
      Loading...
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      fullscreen: {
        type: Boolean,
        default: true
      }
    }
  }
</script>


<style lang="scss">
  .loading-wrapper {
    &.fullscreen {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.85);
      z-index: 1001;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    color:#003c71;
    box-shadow:inset 0 0 200px #fff;
    
    .spinner{
      text-align:center;
    }
  }

  /* The fadeout code */
  @keyframes fadeout {
    /*from {background-color: rgba(255,255,0,0.9);}
    to {background-color: rgba(255,0,255,0);}  */

  }

</style>
