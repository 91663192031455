// calc from data and lines and preference

function draw_svg_chart(svg, {bottom, unit_label, rounding_dp, datasets, width}) {
  if (datasets == undefined) return
  if (datasets.filter(ds => ds.data !== undefined).length < 1) return
  let num = datasets.map(o => o.data.length).reduce((x,y) => x + y)
  let all_values = datasets.map(s => [...s.lines.map(({y}) => y), ...s.data.map(p => p[1])]).reduce((x,y) => x.concat(y), [])
  let max = Math.max(...all_values)
  let min = Math.min(...all_values)
  let granularity = 10 ** (0 - rounding_dp)
  let granularities = {
    bottom: bottom - granularity,
    min: min - granularity,
    max: max + granularity
  }

  // console.log("RANGE", bottom, min, max)

  if (bottom > 0 && bottom == min) {
    bottom = Math.min(granularities.bottom, bottom * 0.95)
  }
  if (bottom < 0 && bottom == min) {
    bottom = Math.min(granularities.bottom, bottom * 1.10)
  }
  if (bottom < 0 && max < 0) {
    max = 0
  }
  if (bottom > 0 && max == bottom) {
    max    = Math.max(granularities.max, (max * 1.05))
    bottom = Math.min(granularities.bottom, bottom * 0.95)
  }
  if (max == 0 && bottom == 0) {
    max = granularity
  }
  if (bottom > 0 && min < bottom) {
    bottom = Math.min(granularities.min, min * 0.95)
  }
  let baseline = max - bottom

  // console.log("BASE zero", max, baseline, bottom)

  // adjust down to relevant units
  bottom = Number(bottom.toFixed(rounding_dp))
  baseline = max - bottom 

  // drop if using no more than 2/5 of space
  if (bottom > 0 && (bottom/max < 0.40 || max < 4)) {
    bottom = 0
    baseline = max - bottom 
  }

  // do y-scale now
  let y_points = [0,1,2,3,4,5,6]
  let y_step = (baseline / y_points.length).toFixed(rounding_dp + 1)
  let y_tick_vals = y_points.map(i => (bottom + i * y_step).toFixed(rounding_dp))
  y_tick_vals.push((bottom + y_points.length * y_step).toFixed(rounding_dp))
  // console.log("Y TICKS", rounding_dp, y_tick_vals)

  // final adjust for top label
  max = bottom + (y_points.length + 1) * y_step
  baseline = max - bottom

  // console.log("SECOND", bottom, baseline, y_step, max)

  // TODO adjusts for small max-min range
  // try to align to chunks?

  // allows space for top label as well
  // TODO need more rounding? 
  // careful - this means baseline + bottom /= max... (leave for now)
  baseline *= 1.04

  // force the box size by CSS settings
  // svg.width etc not set at this point?!
  let outer_w = width || parseInt(svg.clientWidth || svg.parentElement.clientWidth)   || parseInt(svg.style.width) || 500
  let outer_h = parseInt(svg.clientHeight || svg.parentElement.clientHeight) || 200

  // now calculate free space in terms of outer px, so that screen proportions are right

  // drawing inside (base to max)
  let x_axis_space = 50
  let y_axis_space = 55

  // allocate as per pixels
  let inner_w = outer_w - y_axis_space 
  let inner_h = outer_h - x_axis_space

  // bar width depends on how much space per item
  // bar height also scaled to use the vertical space fully
  let x_scale = inner_w / num
  let y_scale = inner_h / baseline 

  // could offset box so (0,0) is graph origin? 
  // svg.viewBox.baseVal.width  = outer_w
  // svg.viewBox.baseVal.height = outer_h
  svg.setAttribute("viewBox", "0 0 " + outer_w + " " + outer_h)

  // console.log("DRAWING", outer_w, inner_w, outer_h, inner_h)
  // console.log("DRAWING", datasets.map(d => d.lines), num, "into", [bottom, min, max, baseline], "scale", [x_scale, y_scale])

  //----------------------------------------  

  // clear it out
  svg.innerHTML = ""

  // zero line, if appropriate
  if (max > 0 && bottom < 0) {
    let zero = document.createElement("rect")
    svg.append(zero)
    zero.setAttribute("x", y_axis_space)
    zero.setAttribute("y", y_scale * (bottom + baseline - 0))
    zero.setAttribute("height", 1)
    zero.setAttribute("width", inner_w)
    zero.setAttribute("fill", 'slategrey')
  }

  var group_offset = 0
  datasets.forEach(function (s) {
    let box = document.createElement("g")
    box.setAttribute("fill", s.colour)
    svg.append(box)

    s.data.forEach(function (d,i) {
      let rect = document.createElement("rect")
      rect.setAttribute("x", x_scale * (i + group_offset) + y_axis_space)
      rect.setAttribute("width", x_scale)

      // todo lift decision, it's not value-dependent
      if (bottom >= 0) {
        let y = d[1] - bottom
        rect.setAttribute("y", y_scale * (baseline - y))
        rect.setAttribute("height", y_scale * y)

      } else if (max < 0) {
        // relative to top
        rect.setAttribute("y", 0)
        rect.setAttribute("height", y_scale * (baseline + bottom - d[1]) * -1)

      } else { // origin in the middle
        if (d[1] < 0) {
          rect.setAttribute("y", y_scale * (baseline + bottom))
          rect.setAttribute("height", y_scale * d[1] * -1)
        } else {
          rect.setAttribute("y", y_scale * (baseline + bottom - d[1]))
          rect.setAttribute("height", y_scale * d[1])
        }
      } 
      box.append(rect)
      let title = document.createElement("title")
      title.innerHTML = "Value: " + d[1]
      rect.append(title)
    })

    s.lines.forEach(function(l) {
      // draw rects accordingly
      let rect = document.createElement("rect")
      rect.setAttribute("x", group_offset * x_scale + y_axis_space)
      rect.setAttribute("y", y_scale * (bottom + baseline - l.y))
      rect.setAttribute("height", 1)
      rect.setAttribute("width", s.data.length * x_scale)
      rect.setAttribute("fill", l.color)
      box.append(rect)
    })
    group_offset += s.data.length
  })

  // bottom axis
  let x_axis = document.createElement("g")
  x_axis.setAttribute("transform", "translate(0," + inner_h + ")")
  x_axis.setAttribute("fill", "none")
  x_axis.setAttribute("font-size", "10")
  x_axis.setAttribute("font-family", "sans-serif")
  x_axis.setAttribute("text-anchor", "middle")
  svg.append(x_axis)

  let x_axis_line = document.createElement("path")
  x_axis_line.setAttribute("class", "domain")
  x_axis_line.setAttribute("stroke", "currentColor")
  x_axis_line.setAttribute("d", "M" + y_axis_space + ",0.5H" + outer_w)
  x_axis.append(x_axis_line)

  let x_points = [0,1,2,3,4,5,6,7,8]
  let indexes = x_points.map(i => Math.floor(i * num / x_points.length))
  // indexes.push(num - 1)
  // console.log(num, indexes)

  let all_data = datasets.map(s => s.data).reduce((x,y) => x.concat(y), [])
  let unique_indexes = [...new Set(indexes)]
  unique_indexes.forEach(function(i) {
    let tick = document.createElement("g")
    tick.setAttribute("class", "tick")
    tick.setAttribute("opacity", "1")
    tick.setAttribute("transform", "translate(" + (x_scale * (i + 0.5) + y_axis_space) + ",0)")
    x_axis.append(tick)
    let line = document.createElement("line")
    line.setAttribute("stroke", "currentColor")
    line.setAttribute("y2", "6")
    tick.append(line)
    let text = document.createElement("text")
    text.setAttribute("fill", "currentColor")
    text.setAttribute("y", "12")
    text.setAttribute("dy", "0.41em")
    text.setAttribute("transform", "rotate(320) translate(-27, -5)")
    text.innerHTML = all_data[i][0]
    tick.append(text)
  })


  // side axis
  let y_axis = document.createElement("g")
  y_axis.setAttribute("transform", "translate(" + (y_axis_space - 1) + ",0)")
  y_axis.setAttribute("fill", "none")
  y_axis.setAttribute("font-size", "10")
  y_axis.setAttribute("font-family", "sans-serif")
  y_axis.setAttribute("text-anchor", "end")
  if (inner_w > 700) { y_axis.setAttribute("id", "yaxis") }
  svg.append(y_axis)

  let y_axis_line = document.createElement("path")
  y_axis_line.setAttribute("class", "domain")
  y_axis_line.setAttribute("stroke", "currentColor")
  y_axis_line.setAttribute("stroke", "red")
  // y_axis_line.setAttribute("d", "M-1,10" + "V" + inner_h)
  y_axis.append(y_axis_line)

  y_tick_vals.forEach(function(t) {
    let tick = document.createElement("g")
    tick.setAttribute("class", "tick")
    tick.setAttribute("opacity", "1")
    tick.setAttribute("transform", "translate(0," + (y_scale * (baseline - (t - bottom))) + ")")
    y_axis.append(tick)
    let line = document.createElement("line")
    line.setAttribute("stroke", "currentColor")
    line.setAttribute("x2", "-6")
    tick.append(line)
    let text = document.createElement("text")
    text.setAttribute("fill", "currentColor")
    text.setAttribute("x", "-9")
    text.setAttribute("dy", "0.32em")
    text.innerHTML = t
    tick.append(text)
  })

  let w_unit = document.createElement("g")
  y_axis.append(w_unit)
  let unit_offset = 0.8 
  w_unit.setAttribute("transform", "translate(" + ((0 - y_axis_space) * unit_offset) + "," + (inner_h / 2) + ")") 

  let unit = document.createElement("text")
  unit.setAttribute("transform", "rotate(-90)")
  unit.setAttribute("fill", "currentColor")
  unit.setAttribute("font-size", "10")
  unit.setAttribute("text-anchor", "middle")
  unit.innerHTML = unit_label
  w_unit.append(unit)

  // hack to force redraw; is there a better way?
  svg.innerHTML = svg.innerHTML 

  let y_clone = document.getElementById('chart-axis')
  if (y_clone) {
    y_clone.setAttribute('viewBox', '-4 5 ' + (y_axis_space + 1) + ' ' + (inner_h + 1))
    y_clone.setAttribute('width', y_axis_space + 9)
    y_clone.setAttribute('height', inner_h + 9)
  }
}


export default {
  namespaced: true,
  draw_svg_chart 
}

