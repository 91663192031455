import api from '../../api'
import moment from 'moment'
import util from '../../util'

// initial state
const state = {
  data: [],
  available_characteristics: [],
  outcomes: [],
  colours: ['#b150c5', '#00adbc', '#f6b436', '#71d54c'],
  results: {
    high: 10,
    low: 0
  },
  charts: [{
    id: 0,
    selected_char: 4
  }]
}


// getters
const getters = {
  filtered_data(state, getters, rootState) {
    let filtered = []
    if (state.data.length == undefined || state.data.length == 0) {
      return filtered
    }
    state.data.forEach(group => {
      if (group.samples != undefined) {
        filtered.push({
          data: group.data,
          samples: group.samples.filter((sample) => sample.f == group.data.fuel_type)
        })
      }
    })
    return filtered
  },
  chart_data(state, getters, rootState, rootGetters) {
    let self = this

    return function(char_id) {
      if (getters.filtered_data.length == undefined) return
      let char_info = rootGetters['characteristics/char_info'](char_id)
      let data = []
      let bottoms = []
      let fuel_types = []

      // data = [ {
      //  name: 'Singapore - Fuel - Grade - Supplier',
      //  colour: self.colours[index],
      //  bottom: lowest number,
      //  limits: { red: 32, amber: 35 },
      //  lines: [ { y: 32, colour: 'red'} ],
      //  average: 34,
      //  data: [['12th Dec', 31], ['13th Dec', 31.6]]
      // }]
      let i = 0


      getters.filtered_data.forEach(group => {
        let selection_data = {}
        let port_samples = group.samples
        let selection = group.data

        let samples = filter_by_char(port_samples, char_id);

        if(samples.length > 0) {

          let points = samples.map(s => [moment(s.d).format('Do MMM'), s.v[char_id]])
          let min_data_val = Math.min(...samples.map(s => s.v[char_id]))
          let data_average = util.avg(samples.map(s => s.v[char_id]))

          let bottom = min_data_val // let chart do the rest
          selection_data.bottom = bottom
          bottoms.push(bottom)

          let fvmm = char_info.fv_mm(selection.fuel_type)

          selection_data.data = points.map(x => [x[0], fvmm.data_val(x[1])])
          selection_data.average_txt = fvmm.report_val(data_average) // for key
          selection_data.lines  = [{'y': fvmm.round_fn(fvmm.data_val(data_average)), 'color': '#3b8ede'}]

          selection_data.name = selection.port_name + " - " + selection.grade + " - " + rootGetters['suppliers/supplier_name'](selection.supplier)
          selection_data.colour = state.colours[i]

          let limits = rootGetters['characteristics/chart_limits'](char_id, selection.grade, selection.fuel_type)
          if (limits) {
            selection_data.limits = limits
            if (limits.red)    { 
              selection_data.lines.push({'y': limits.red,    'color': '#dc3545'}) 
              limits.red_txt = fvmm.report_val(limits.red)
            }
            if (limits.amber)  { 
              selection_data.lines.push({'y': limits.amber,  'color': '#ffc107'}) 
              limits.amber_txt = fvmm.report_val(limits.amber)
            }
            if (limits.bottom) { 
              selection_data.lines.push({'y': limits.bottom, 'color': '#87cefa'}) 
              limits.bottom_txt = fvmm.report_val(limits.bottom)
            }
          }
          fuel_types.push(selection.fuel_type)
        }

        data.push(selection_data)
        i += 1

      })

      let bottom = Math.min(...bottoms)
      let unit_label = char_info.unit
      let rounding_dp = Math.max(...fuel_types.map(t => char_info.fv_mm(t).rounding_dp))

      data = data.sort((a,b) => (b.data || []).length - (a.data || []).length)

      return {data, bottom, unit_label, rounding_dp}
    }
  },

  histogram_data(state, getters, rootState, rootGetters) {
    let self = this

    return function(char_id) {

      let data = {
        datasets: [],
      }
      if (getters.filtered_data.length == undefined) return { data }

      let collected_samples = {}
      let all_samples = []

      let i = 0

      getters.filtered_data.forEach(group => {

        let port_samples = group.samples
        let key = group.data.port_name+ " - " + group.data.grade+ " - " + rootGetters['suppliers/supplier_name'](group.data.supplier)

        let samples = filter_by_char(port_samples, char_id);
        if(samples.length > 0) {
          collected_samples[key] = []

          samples.forEach(function(sample) {
            let float = sample.v[char_id]
            collected_samples[key].push(float)
            all_samples.push(float)
          })
        }
      })

      let min = Math.min(...all_samples)
      let max = Math.max(...all_samples)
      if (max == min) {
        max = max + 11
        min = min - 9
      }
      let width = (max-min)/10

      for (var port_id in collected_samples) {
        let dataset = {
          data: [{x: 0, y: 0}],
          borderColor: state.colours[i],
          fill: false,
          pointRadius: 0,
          label: port_id
        }

        for(let loop = 0; loop < 10; loop++) {
          let low = min + (loop * width)
          let high = min + ((loop + 1) * width)
          let freq = collected_samples[port_id].reduce((acc, val) => {
            if (loop == 9) {
              return (val <= high && val >= low) ? acc + 1 : acc
            }
            else {
              return (val < high && val >= low) ? acc + 1 : acc
            }
          }
          , 0)
          if (loop > 0) {
            dataset.data.push(null)
          }
          dataset.data.push({x: (loop*2)+1, y: (freq/collected_samples[port_id].length)*100})

        }
        dataset.data.push({x:20, y: 0})

        data.datasets.push(dataset)
        i += 1

      }
      return {data, min, max, 'step_size': width}
    }
  },
  filtered_outcomes(state, getters, rootState) {
    return function(char_id, grade, supplier, fuel_type) {
      return state.outcomes.filter((outcome) => {
        let match = (outcome.g == grade && outcome.f == fuel_type && outcome.c == char_id && outcome.v)

        if (supplier != null) {
          match = (match && outcome.p == supplier)
        }

        return match
      })
    }
  },
  filtered_limits(state, getters, rootState) {
    return function(char_id, grade, fuel_type) {
      let revision = rootState.options.selected_revision
      let matches = rootState.characteristics.
                    limits[revision].
                    filter((limit) => limit.char_id == char_id &&
                                        limit.grade == grade &&
                                        (limit.sulphur_band == null || limit.sulphur_band == fuel_type))
      return matches[0]
    }
  },
  errors(state) {
    if (state.data.length == undefined || state.data.length == 0) return []
    return state.data.map(d => d.error).filter(e => e != undefined)
  }
}


// actions
const actions = {
  getAllSamples ({ commit, dispatch, rootState }) {
    commit('setSamples', {})
    dispatch('options/set_loading', true, {root: true})
    api.get('api/v1/samples/comparison', {params: {
      ports: rootState.options.selected_comparison_ports,
      from:  rootState.options.dates.from,
      to:    rootState.options.dates.to
    }})
    .then(response => {
      commit('setSamples', response.data)
      dispatch('options/set_loading', false, {root: true})
    })
    .then(() => {
      dispatch('updateCharacteristics')
    })
    .catch(e => {
      console.log(e)
    })
  },
  getOutcomes({ commit, rootState }) {
    let port_id = rootState.options.selected_comparison_ports[0].port_id
    api.get('api/v1/limits', {params: {
      ports: [port_id],
      from: rootState.options.dates.from,
      to: rootState.options.dates.to
    }})
    .then(response => {
      let samples = response.data
      commit('setOutcomes', samples)
    })
  },
  updateCharacteristics({ commit, state, rootState, getters, dispatch }) {
    if (getters.filtered_data.length == undefined) return
    let port_chars, char_ids
    port_chars = []
    char_ids = []

    getters.filtered_data.forEach(group => {
      let port_array = []
      group.samples.forEach((sample) => port_array = port_array.concat(Object.keys(sample.v)))

      port_array = [...new Set(port_array)]
      port_chars.push(port_array)
    })

    // was intersection before
    // now calc the union 
    char_ids = [... new Set(port_chars.reduce((x,y) => x.concat(y), []))]

    let avail = rootState.characteristics.data.filter(char => char_ids.includes(char.id.toString()))

    commit('setCharacteristics', avail)
    if (avail.length > 0) {
      dispatch('updateCharts')
    }
  },
  addChart ({ state, commit }) {
    let ids      = state.charts.map(c => c.id)
    let existing = state.charts.map((chart) => chart.selected_char)
    let unused   = state.available_characteristics.filter((char) => !existing.includes(char.id))
    let chart = {
      id: Math.max(...ids) + 1,
      selected_char: unused[0].id
    }
    commit('newChart', chart)
  },
  updateCharts ({ state, commit }) {
    state.charts.forEach((chart, index) => {
      let payload = {
        id: index,
        selected_char: state.available_characteristics[index].id
      }
      commit('updateCharacteristic', payload)
    })
  },
  updateChar ({ commit }, payload) {
    commit('updateCharacteristic', payload)
  },
  removeChart ({ commit, state }, id) {
    let index = state.charts.findIndex(c => c.id == id)
    commit('removeChart', index)
  }
}

// mutations
const mutations = {
  setSamples (state, samples) {
    state.data = samples
    return samples
  },
  setOutcomes (state, outcomes) {
    state.outcomes = outcomes
  },
  setCharacteristics (state, avail) {
    state.available_characteristics = avail
  },
  newChart (state, chart) {
    state.charts.push(chart)
  },
  updateCharacteristic (state, payload) {
    state.charts.filter(c => c.id == payload.id)[0].selected_char = payload.selected_char
  },
  removeChart (state, index) {
    let array = state.charts
    if (index !== -1) {
      array.splice(index, 1)
    }
    state.charts = array
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// helpers
function filter_by_char(samples, char_id) {
  return samples.filter(({v}) => v[char_id] !== undefined)
}
