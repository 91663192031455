<template>
  <div class="overlay-wrapper" v-bind:class="{ active: active }">
    <div class="map-overlay"  v-bind:class="{ active: active }">
      <div class="header"  v-on:click="active = !active">
        <div><img src="./../assets/images/lr_logo.svg"  id="logo" /></div>
        <div class="row">
          <a href="https://s3.amazonaws.com/Neptune/stats_presentation/FOBAS-Stats-Presentation" target="_blank" style="text-decoration: none;">
            <button type="button" class="btn btn-primary btn-sm">
              <i class="fas fa-question-circle"></i> Overview
            </button>
          </a> &nbsp;
          <b-button
            v-b-modal.help-modal

            size="sm"
            class="btn-primary">
            <i class="fas fa-info-circle"></i>
            Help
          </b-button>
        </div>
        <div class="toggler"><i class="fas fa-angle-up"  v-bind:class="{ active: !active }"></i><i class="fas fa-angle-down"  v-bind:class="{ active: active }"></i></div>
      </div>

      <b-container>

        <div class="clearfix">
          <h4 class="mb-3 float-left">Welcome, {{user.name}}</h4>
          <b-button size="sm" class="float-right" @click="handleLogout"><i class="fas fa-sign-out-alt"></i> Log out</b-button>
          <b-button size="sm" class="float-right mr-1" @click="reset_map" v-if="map_changed"><i class="fas fa-globe"></i> Reset map</b-button>

        </div>
        <div v-if="user.show_subscription_notice" class="clearfix" style="background-color: #c8c8c8; padding-top: 10px; padding-bottom: 10px; padding-left: 10px; padding-right: 10px; margin-bottom: 10px;">
          <span v-html="user.subscription_notice"></span>
        </div>
        <b-form-group label-cols-sm="3" label="Date from:">
          <b-input-group prepend="From" class="prepend">
          <b-date-picker
            :config="fromConfig"
            :value="dates.from"
            @dp-change="update_from_date"
          ></b-date-picker>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="3" label="Date to:">
          <b-input-group prepend="To" class="prepend">
          <b-date-picker
            :config="toConfig"
            :value="dates.to"
            @dp-change="update_to_date"
          ></b-date-picker>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="3" label="Port name:">
          <b-input-group>
            <b-input :value="port_search" @input="update_port_name" />
          </b-input-group>
        </b-form-group>

        <fieldset id="" role="group" aria-labelledby="" class="b-form-group form-group">
          <div class="form-row">
            <legend class="col-sm-3 col-form-label">Fuel type:</legend>
            <div role="group" aria-labelledby="" class="col-sm-9" id="fuels">
              <div v-for="key in keys"
                :key="key.id"
                :class="keyClass(key.id)"
                @click="toggleMapFuelType(key.id)"
                v-b-tooltip.hover.html
                :title="key.desc">
                <img :src="key.img">
                <span>{{key.name}}</span>
                <i class="fas fa-check"></i>
              </div>
            </div>
          </div>
        </fieldset>

        <b-alert variant="secondary" :show="show_data_alert()">
          <p class="my-1">{{alert_message}}</p>
        </b-alert>

        <b-card
          title="My ports"
          class="my-3 comparison-list"
          v-if="comparison_ports.length > 0"
        >
          <b-list-group flush>
            <b-form-checkbox-group
              :checked="selected_comparison_ports"
              @change="update_selected_comparison_ports"
            >
              <b-list-group-item v-for="(port, index) in comparison_ports" :key="index">
                <div>
                  <img :src="port_icon(port.fuel_type)" v-if="port.fuel_type" class="pin">
                    <b-form-checkbox :value="port" :disabled="check_disabled(port)">
                      {{port.port_name}}<br/>
                      <span class="tini">{{port_details(port)}}</span>
                    </b-form-checkbox>
                </div>
                <div class="buttons-group">
                  <b-button
                          v-if="port.samples"
                          @click="view_selection(port)"
                          variant="info"
                          class="btn-xs"
                  ><i class="fas fa-eye"></i> <span>View data</span></b-button>
                  <div
                    v-b-tooltip
                    title="There is no data available for this selection between the specified dates. Maybe we could help? Please contact us."
                    v-else >

                    <b-button
                            variant="info"
                            class="btn-xs disabled"
                    ><i class="fas fa-eye-slash"></i> <span>No data</span></b-button>
                  </div>


                  <b-button
                    @click="remove_from_comparison(index)"
                    variant="danger"
                    class="btn-xs"
                  ><i class="fas fa-trash-alt"></i> <span>Remove</span></b-button>
                </div>
              </b-list-group-item>
            </b-form-checkbox-group>
          </b-list-group>
          <div id="list-buttons" v-if="show_comparison_buttons">
            <b-button variant="primary" id="btn-compare" @click="set_view('comparison')">Compare selected</b-button>
            <report-button :email_address="user_email" />
          </div>
        </b-card>
      </b-container>
    </div>
    <div id="sideToggle" v-on:click="active = !active">
      <i class="fas fa-angle-left"  v-bind:class="{ active: active }"></i><i class="fas fa-angle-right"  v-bind:class="{ active: !active }"></i>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import bDatePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {eventBus} from '../packs/stats';
import api from '../api.js'
import fuel_bands from '../store/modules/fuel_bands.js'
import reportButton from './ReportButton'


const default_map_zoom = 3
const default_map_pos  = [+15,-30] // lat, lng


export default {
  data() {
    let dateConfig = {
      format: 'DD/MM/YYYY',
      maxDate: moment().endOf('day'),
      icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'far fa-times-circle'
      },
      widgetPositioning: {
        vertical: 'bottom'
      }
    }
    return {
      active: false,
      fromConfig: Object.assign({
        minDate: moment().subtract(3, 'months').startOf('day')}, dateConfig),
      toConfig: Object.assign({
        minDate: this.dates == undefined ? moment().subtract(3, 'months').startOf('day') : this.dates['from']}, dateConfig),
      keys: [
        {
          name: 'ULSFO',
          img: require('./../assets/images/map-icons/1x/ULSFO.png'),
          id: 'ULSFO',
          desc: '<strong>Ultra Low Sulphur Fuel Oil</strong><br />A fuel oil with a Sulphur content of maximum 0.10% m/m',
        },
        {
          name: 'VLSFO ',
          img: require('./../assets/images/map-icons/1x/VLSFO.png'),
          id: 'VLSFO',
          desc: '<strong>Very Low Sulphur Fuel Oil</strong><br />A fuel oil with a Sulphur content between 0.10 and 0.50% m/m'
        },
        {
          name: 'HSFO',
          img: require('./../assets/images/map-icons/1x/HSFO.png'),
          id: 'HSFO',
          desc: '<strong>High Sulphur Fuel Oil</strong><br />A fuel oil with a sulphur content greater than 0.50% m/m.'
        },
        {
          name: 'ULSGO',
          img: require('./../assets/images/map-icons/1x/ULSGO.png'),
          id: 'ULSGO',
          desc: '<strong>Ultra Low Sulphur Gas Oil</strong><br />A gas oil fuel with a Sulphur content of maximum 0.10% m/m'
        },
        {
          name: 'VLSGO',
          img: require('./../assets/images/map-icons/1x/VLSGO.png'),
          id: 'VLSGO',
          desc: '<strong>Very Low Sulphur Gas Oil</strong><br />A gas oil fuel with a Sulphur content between 0.10 and 0.50% m/m'
        },
        {
          name: 'HSGO',
          img: require('./../assets/images/map-icons/1x/HSGO.png'),
          id: 'HSGO',
          desc: '<strong>High Sulphur Gas Oil</strong><br />A gas oil with a sulphur content greater than 0.50% m/m.'
        },
        /*{
          name: 'Alert',
          img: require('./../assets/images/map-icons/1x/alert.png'),
          id: 'alerts'
        },
        {
          name: 'Bulletin',
          img: require('./../assets/images/map-icons/1x/bulletin.png'),
          id: 'bulletins'
        }*/
      ],
      comparison_fuel: null,
      user_email: '',
      alert_message: ''
    }
  },
  computed: {
    ...mapState('options', [
      'comparison_ports',
      'selected_comparison_ports',
      'dates',
      'fuel_types',
      'selected_fuel_type',
      'loading',
      'banner_seen',
      'map_zoom',
      'map_centre'
    ]),
    ...mapState('ports', {
      map_fuel_types: 'map_fuel_types',
      port_search: 'query',
      all_ports: 'all_ports'
    }),
    ...mapState('auth', ['user']),
    ...mapGetters('ports', ['port_by_id', 'filtered_ports', 'port_name']),
    ...mapGetters('suppliers', ['supplier_name']),
    show_comparison_buttons() {
      let ports = this.comparison_ports.filter(p => this.selected_comparison_ports.some(c => c.port_id === p.port_id && p.samples === true && c.grade === p.grade && p.supplier === c.supplier && p.fuel_type === c.fuel_type))
      return ports.length > 1
    },
    comparison_data() {
      let c_data = []
      this.comparison_ports.forEach(port => {
        if (this.port_name(port.port_id) != undefined) {
          let d = {
            port_name: this.port_name(port.port_id),
            port_id: port.port_id,
            fuel_type: port.fuel_type,
            grade: port.grade,
            supplier: port.supplier
          }
          c_data.push(d)
        }
      })
      return c_data
    },
    map_changed() {
      return (this.map_zoom != default_map_zoom || this.map_centre != default_map_pos)
    }
  },
  methods: {

    ...mapActions('options', [
      'remove_from_comparison',
      'set_date',
      'update_fuel_type',
      'view_single_port',
      'view_selection',
      'set_selected_comparison_ports',
      'set_view'
    ]),
    ...mapActions('ports', [
      'addMapFuelType',
      'removeMapFuelType',
      'updateSearchQuery'
    ]),
    ...mapActions('auth', ['logout']),
    handleLogout() {
      this.logout('welcome')
    },
    update_from_date(event) {
      this.set_date({value: event.date, type: 'from'})
      this.toConfig.minDate = event.date
    },
    update_to_date(event) {
      this.set_date({value: event.date, type: 'to'})
      this.fromConfig.maxDate = event.date
    },
    update_port_name(e) {
      this.updateSearchQuery(e);
      this.check_visible_markers();
    },
    reset_map(e) {
      this.$parent.$refs.leaflet.mapObject.setView(default_map_pos, default_map_zoom, {
        pan: { animate: true },
        zoom: { animate: true },
        animate: true
      })
    },
    check_visible_markers() {
      // If only one port, centre and zoom map to it
      if (this.filtered_ports.length == 1) {
        var port = this.filtered_ports[0]
        // pan and zoom in one
        this.$parent.$refs.leaflet.mapObject.setView([port.lat, port.lng - 4], 6, { pan: { animate: true }, zoom: { animate: true }, animate: true })
      }
      else {
        // Run check for visible markers
        var active_markers = document.querySelectorAll('.leaflet-marker-icon')
        var right_bound = window.innerWidth - 48
        var lower_bound = window.innerHeight - 52
        var visible_markers = [...active_markers].map(m => m.getBoundingClientRect()).filter(m => {
          return m.x >= 0 &&
          m.x <= right_bound &&
          m.y >= 0 &&
          m.y <= lower_bound
        })
        // If no visible markers then reset map zoom
        if (visible_markers.length == 0) {
          this.reset_map()
        }
      }
    },
    keyClass(fuel_type) {
      return {
        fuel: true,
        selected: this.map_fuel_types.includes(fuel_type)
      }
    },
    port_icon(ft) {
      ft = fuel_bands.display_band(ft)
      return require("./../assets/images/map-icons/1x/"+ft+".png")
    },
    port_details(port) {
      let supplier = this.supplier_name(port.supplier)
      return port.fuel_type + " - " + port.grade + " - " + supplier
    },
    update_selected_comparison_ports(ports) {
      if (ports.length == 0) {
        this.comparison_fuel = null
      }
      else {
        let fuel_type = ports[0].fuel_type
        this.comparison_fuel = fuel_type.match('GO$') ? "GO" : "FO"
      }
      this.set_selected_comparison_ports(ports)
    },
    show_data_alert() {
      if (this.filtered_ports == null || this.loading) {
        return false
      }
      else if (this.port_search.length > 0) {
        this.alert_message = "No ports were found for that name."
        return this.filtered_ports.length == 0
      }
      else {
        this.alert_message = "There isn't much data available for the current filter selection. Maybe we could help? Please feel free to contact us."
        return this.filtered_ports.length < 20
      }
    },
    not_in_ports(port) {
      //console.log(port)
      return this.all_ports.filter(p => p[port.fuel_type] != undefined && p.id == port.port_id).length == 0
    },
    check_disabled(port) {
      if (port.samples == false) {
        return true
      }
      else if (this.selected_comparison_ports.length > 3 && !this.selected_comparison_ports.includes(port)) {
        return true
      }
      else if (this.comparison_fuel != null) {
        return !(port.fuel_type.match(this.comparison_fuel+'$'))
      }
    },
    toggleMapFuelType(fuel_type) {
      this.map_fuel_types.includes(fuel_type) ? this.removeMapFuelType(fuel_type) : this.addMapFuelType(fuel_type)
      this.update_fuel_type(this.map_fuel_types[0])
    },
  },
  components: {
    bDatePicker,
    reportButton
  },
  created() {
    eventBus.$on('openSideToggle', (data) => {
        this.active = !this.active;
    });
  },
  mounted() {
    if (this.selected_comparison_ports !== undefined && this.selected_comparison_ports.length > 0) {
      let port = this.selected_comparison_ports[0]
      this.comparison_fuel = port.fuel_type.match('GO$') ? "GO" : "FO"
    }
    this.active = this.banner_seen
    this.reset_map(null) // initial zoom
  },
  watch: {
    user(newUser, oldUser) {
      this.user_email = JSON.parse(JSON.stringify(newUser.email))
    },
    comparison_data(newPorts, oldPorts) {
      this.$nextTick();
    }
  }
}



</script>

<style lang="scss">

$marine-green: #04aa9e;
$link: #1A8479;
$link-pressed: #04aa9e;

$full-green: #10715e;
$pale-green: #b0d65e;
$yellow: #f3d044;
$cambusa: #faa869;
$dark-blush: #bb6786;
$lilla: #6f64cb;
$purple: #70378d;
$atlantic: #0d4665;

  /*.overlay-wrapper{
    opacity: 1;
  }
  .overlay-wrapper.hidden{
    opacity: 0;
  } */
  .map-wrapper{
    position:relative;
    height: 100vh;
  }
  .map-overlay{
    position:absolute;
    background: #fff;
    transition:all .3s ease;
    z-index: 1000;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    /*height: auto;   */
    width: 100%;
    /*top: 100%;
    margin-top: -80px;
    max-height: 100vh; */
    overflow: auto;
    transition: all .2s ease;

    /*top: auto;   */
    bottom: 0;
    height: 50px;
    opacity: 1;
  }
  .map-overlay.active{
    top: auto;
    bottom: 0;
    height:auto;
    max-height: 100%;
    margin-top: 0;
  }
  .map-overlay .header{
    justify-content: space-between;
    cursor: pointer;
  }
  .header .fas{
    display:none;
  }
  .header .fas.active{
    display:block;
  }
  .map-overlay .container{
    padding: 7px;
  }
  .map-overlay:hover{
    background: #fff;
  }
  .map-overlay .form-group{
    margin-bottom: 10px;
  }
  .form-group .col-form-label{
    display:none;
  }
  .form-group .prepend .input-group-text{
    width:65px;
  }
  #fuels{
    display:flex;
    justify-content: space-between;
  }
  #fuels .fuel{
    width:23%;
    min-width: 50px;
    padding: 5px;
    background:#fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    text-align:center;
    cursor: pointer;
    transition: all .3s ease;
  }
  #fuels .fuel:hover{
    background:#f5f5f5;
  }
  #fuels .fuel.selected{
    background:  rgba($marine-green, 0.2);
    border: 1px solid $marine-green;
    position:relative;
  }


  .fuel .fas{
    display:none;
  }
  .fuel.selected .fas {
    position: absolute;
    width: 16px;
    height: 16px;
    background: $marine-green;
    border-radius: 8px;
    top: -5px;
    left: -5px;
    color:#fff;
    font-size: 9px;
    line-height: 16px;
    display:block
  }
  #fuels .fuel img{
    max-width:100%;
  }
  #fuels .fuel span{
    font-size: 11px;
    font-weight: 600;
    display:block;
  }

  .list-group-flush .list-group-item{
    display: flex;
    padding: .5rem;
    justify-content: space-between;
  }
  .list-group-flush .list-group-item img.pin{
    max-height: 40px;
    width: auto;
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .list-group-flush .list-group-item .custom-control{
    margin-left: 40px;
    line-height: .85;
    span.tini{
      font-size:.65rem;
      font-weight:600;
    }
  }
  .list-group-flush .list-group-item .buttons-group {
    min-width: 171px;
    text-align: right;

    div {
      display: inline-block;
    }

    .btn{
      margin-left: .25rem;

      span {
        display:none;
      }
    }
  }
  #list-buttons{
    /*display:flex;
    justify-content: space-around;
    align-items: center;     */
  }
  #btn-compare, #report-dropdown{
    margin-top: .75rem;
    margin-right: .25rem;
    font-size: .78rem;
    box-shadow: none;
  }

  #report-dropdown{
    margin-right:0;
  }

  #sideToggle{
    position: absolute;
    top: 71px;
    left: 525px;
    color: #000;
    background: #fff;
    padding: 20px 7px;
    z-index: 500;
    border-radius: 0 3px 3px 0;
    transition: all .2s ease;
    display:none;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  }
  #sideToggle i{
    display:none;
  }
  #sideToggle i.active{
    display:block;
  }
  #sideToggle:hover{
    color:#fff;
    cursor: pointer;
    padding: 20px 15px;
    background:$link-pressed;
  }

  @media screen and (min-width: 400px){
    #btn-compare, #report-dropdown{
      font-size: 1rem;
    }
  }

  @media screen and (orientation:portrait) and (min-width:576px){
    .map-overlay .form-group{
      margin-bottom: 1rem;
    }
    .map-overlay .form-group .col-form-label{
      display:block;
    }
    .form-group .prepend .input-group-text{
      display:none;
    }
    .map-overlay .input-group>.custom-select:not(:first-child), .map-overlay .input-group>.form-control:not(:first-child) {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
  }

  @media screen and (min-width: 500px){
    .map-overlay{
      max-height: 60vh;
    }
    .map-overlay .container{
      padding: 20px;
    }
    .map-overlay .header{
      padding: 10px 20px;
    }
    .list-group-flush .list-group-item .buttons-group .btn span{
      display:inline;
      margin-left: 5px;
    }
    #btn-compare, #report-dropdown{
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 900px){
    .map-overlay {
      height: 100vh;
      max-height: 100vh;
      top: 0;
      width: 25%;
      min-width: 525px;
      border-right: 1px solid #fff;
      margin-top: 0;
    }
    .map-overlay.active{
      height: 100vh;
    }
    .map-overlay .header{
      cursor: default;
    }
    .toggler{
      display:none;
    }
    .form-group{
      margin-bottom: 1rem;
    }
    .form-group .col-form-label{
      display:block;
    }
    .form-group .prepend .input-group-text{
      display:none;
    }
    .map-overlay .input-group>.custom-select:not(:first-child), .map-overlay .input-group>.form-control:not(:first-child) {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
  }
  /*@media screen and (orientation: landscape) and (min-width: 2100px){
    .map-overlay {
      background: #fff;
    }
  } */

  @media screen and (min-width: 900px) /*and (max-width: 2500px)*/{
    .overlay-wrapper{
      position: absolute;
      width: 525px;
      height: 100vh;
      top: 0;
      z-index: 500;
      left: -525px;
      transition: all .35s ease;
    }
    .overlay-wrapper.active{
      left: 0;
    }
    #sideToggle{
      display:block;
    }
  }

  @media screen and (max-width: 900px) {
    .overlay-wrapper {
      .container {
        display: none;
      }

      &.active {
        .container {
          display:block;
          min-height: 425px;
        }
      }
    }

    .map-overlay .header {
      > :nth-child(1) {
        order: 2;
      }
      > :nth-child(2) {
        order: 1;
        margin-left: 0;
      }
      > :nth-child(3) {
        order: 3;
        text-align: right;
        width: 70px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .list-group-flush .list-group-item .buttons-group {
      min-width: 57px;
    }
  }

</style>
