import axios from 'axios'
import store from './store/index.js'

let myApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'StatsAuthorization': localStorage.getItem('auth-token')
  }
})

myApi.interceptors.response.use(null, function(error) {
  if (error.response.status === 401) {
    store.dispatch('auth/logout', 'expired', { root: true })
    return {data: []}
  }
  return Promise.reject(error)
})

export default myApi