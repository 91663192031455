<template>
  <div class="map-wrapper ">
    <l-map
      :zoom="map_zoom"
      :min-zoom="2"
      ref="leaflet"
      :center="map_center"
      :options="mapOptions"
      :max-bounds-viscosity="1"
      :max-bounds="bounds"
      @moveend="update_center"
    >
      <l-tile-layer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"></l-tile-layer>
      <l-control-zoom position="topright"></l-control-zoom>
      <l-marker-cluster>
        <l-marker v-for="p in filteredPorts" :lat-lng="[p.lat, p.lng]" :icon="map_marker_type(p)" :key="p.id">
          <l-popup>
            <map-popup @show-modal="openModal" :port="p"></map-popup>
          </l-popup>
          <l-tooltip>{{p.nm}}</l-tooltip>
        </l-marker>
      </l-marker-cluster>


      <l-feature-group v-for="a in eca_areas" v-bind:key="a.label">
        <l-polyline v-for="l in a.lines" v-bind:key="l[0]+l[1]" :lat-lngs="l" :color="'red'" :fill=false>
          <l-tooltip :options="{direction: 'auto'}">{{ a.label }}</l-tooltip>
        </l-polyline>
        <l-rectangle :bounds="a.rectangle" :fill-opacity="0.7">
           <l-tooltip :data-eca-link="a.link" :options="{permanent: true, direction: 'center'}"><a :href="a.link" class="stretched-link" target="_blank">{{ a.label }}<br>{{a.limit}}</a></l-tooltip>
        </l-rectangle>
      </l-feature-group>
    </l-map>
    <map-overlay></map-overlay>
    <banner v-if="!banner_seen"></banner>
    <b-modal
      v-show="!loading"
      ref="port_selector"
      :title="modal_title"
      @ok="add_to_comparison"
      ok-variant=" btn-sm btn-success"
      cancel-variant=" btn-sm btn-secondary"
    ><port-selector></port-selector></b-modal>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LControlZoom, LTooltip, LPolyline, LRectangle, LFeatureGroup } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import mapPopup from './MapPopup.vue'
import mapOverlay from './MapOverlay.vue'
import banner from './Banner.vue'
import portSelector from './PortSelector.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import { icon_names } from '../assets/js/constants.js'
import { eca_data } from '../assets/js/eca_data.js'

export default {
  data: function() {
    let map_icons = this.setup_icons()
    return {
      mapOptions: {
        zoomControl: false
      },
      bounds: L.latLngBounds([
        [-89.98155760646617, -260],
        [89.99346179538875, 190]
      ]),
      icons: map_icons,
      modal_title: '',
      eca_areas: eca_data
    }
  },
  mounted() {
  },
  methods: {
    map_marker_type(port) {
      let chars = []
      if (port.HSFO > 0) chars.push('HSFO')
      if (port.VLSFO > 0) chars.push('VLSFO')
      if (port.ULSFO > 0) chars.push('ULSFO')
      if (port.HSGO > 0) chars.push('HSGO')
      if (port.ULSGO > 0) chars.push('ULSGO')
      if (port.VLSGO > 0) chars.push('VLSGO')

      if (port.alerts !== null) {
        //chars.push('alert')
      }
      let icon_name = chars.sort().join('-')
      if (this.icons !== undefined) {
        return this.icons[icon_name]
      }
    },
    setup_icons() {
      let icons = {}
      icon_names.forEach(name => {
        icons[name] = L.icon({
          iconUrl: require('../assets/images/map-icons/1x/'+name+'.png'),
          iconRetinaUrl: require('../assets/images/map-icons/4x/'+name+'@4x.png'),
          iconSize: [48, 52],
          iconAnchor: [24, 46],
          popupAnchor: [0,-52]
        })
      })
      return icons
    },
    update_center(e) {
      let obj = {
        center: e.target.getCenter(),
        zoom:   e.target.getZoom()
      }
      this.set_map_options(obj)
    },
    openModal(port_id) {
      if (this.selected_port !== port_id) {
        this.set_single_port(port_id)
        this.modal_title = this.port_by_id(port_id)[0].nm
        this.$store.dispatch('single_port/getAllSamples')
      }
      this.$refs.port_selector.show()
    },
    ...mapActions('options', [
      'set_single_port',
      'add_to_comparison',
      'update_fuel_type',
      'set_map_options'
    ])
  },
  computed: {
    ...mapGetters('ports', {
      filteredPorts: 'filtered_ports',
      port_by_id: 'port_by_id'
    }),
    ...mapState('options', [
      'selected_port',
      'selected_fuel_type',
      'selected_grade',
      'selected_supplier',
      'loading',
      'comparison_ports',
      'banner_seen',
      'map_zoom',
      'map_center'
    ])
  },
  components: {
    mapOverlay,
    banner,
    mapPopup,
    portSelector,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlZoom,
    LTooltip,
    LPolyline,
    LRectangle,
    LFeatureGroup,
    'l-marker-cluster': Vue2LeafletMarkerCluster
  }
}


</script>

<style>

  .map-wrapper {
    position: relative;
    height: 100%;
  }
  .vue2leaflet-map {
    height: 100%;
    width: 100%;
    left: 0;
  }
  /*@media screen and (min-width: 2500px){
    .vue2leaflet-map {
      height: 100%;
      width: 75% !important;
      left: 25%;
    }
  } */
  .leaflet-container .leaflet-tooltip a {
    color: #414042;
  }
</style>
