<template>

    <div class="landing" v-bind:class="{ active: active }">
        <div class="inactive"></div>

        <div class="banner" v-on:click="handleClick" v-bind:class="{ active: active }">
            <div class="logo"><img src="../assets/images/lr-logo.png" /></div>
            <div class="title">Lloyd's Register improving safety, quality and performance.</div>
            <div class="explain" v-if="isGlobe">Click the banner to gain access to all the ports detailed information.</div>
            <div class="explain" v-else>
              <p> Please log in using the button on your LRGMT client dashboard. </p>
              <p> Alternatively, sign up for a <a href="https://www.lrgmt.com/signup">Trial Account</a>, with the option to subscribe for longer access.
</p>
            </div>
            <div class="semiCircle" v-if="isGlobe"></div>
        </div>
    </div>

</template>


<script>
    import {eventBus} from '../packs/stats'
    import { mapActions, mapState } from 'vuex'
    export default {
    data() {
        return {
            active: true
        }
    },
    computed: {
        ...mapState('options', ['view']),
        isGlobe() {
            return this.view == 'globe'
        }
    },
    methods: {
        ...mapActions('options', ['set_banner_seen']),
        handleClick(){
            if (this.isGlobe) {
                this.active = !this.active;
                eventBus.openSideToggle();
                setTimeout(() => this.set_banner_seen(true), 5000)
            }
        }
    }
}

</script>


<style lang="scss">
    .landing.active{
        position: absolute;
        content: "";
        width: 100%;
        height: 100vh;
        z-index: 1002;
        top: 0;
    }
    .inactive{
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: 100vh;
    }
    .landing{
        top: -100vh;
        .inactive{
            height:0;
        }
    }
    .banner{
        position: absolute;
        width:100%;
        top: -450px;
        height: 400px;
        z-index: 2000;
        transition: all .3s ease;
        cursor: pointer;
        background: url("../assets/images/banner.jpg") right bottom no-repeat #003c71;
        background-size: cover;
        display: flex;
        justify-content: space-around;
        flex-flow: column;
        text-align: center;
        color:#fff;
        padding: 50px 0;
        .logo{
            img{
                max-width: 100px;
                height: auto;
            }
        }
        .title{
            font-size: 2rem;
            font-weight: 600;
            padding: 0 12%;
            line-height: 1.9rem;
        }
        .explain{
            font-size: 1rem;
            font-weight: 500;
            padding: 0 10%;
            p{
              margin-bottom: 0;
            }
          a{
            color: #fff;
            font-weight: 900;
            border-bottom:2px solid #fff;
            transition: all .3s ease;
            padding: 0 5px;
          }
            a:hover{
              text-decoration: none;
              border-bottom: none;
              color: #04aa9e;
              background: #fff;

            }
        }
    }

    .banner:after{
        position:absolute;
        content:"";
        width:100%;
        height: 100%;
        z-index: -1;
        left:0;
        top: 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3b8ede+0,003c71+100&0+0,0.85+100 */
        background: -moz-linear-gradient(top, rgba(0,169,157,0) 0%, rgba(0,169,157,0.5) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,169,157,0) 0%,rgba(0,169,157,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,169,157,0) 0%,rgba(0,169,157,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003b8ede', endColorstr='#d9003c71',GradientType=0 ); /* IE6-9 */
    }
    .banner.active{
        top:0;
    }
    .semiCircle{
        background: #fff;
        width: 40px;
        height: 20px;
        border-radius: 20px 20px 0 0;
        border: 1px solid #fff;
        position: absolute;
        bottom: 0;
        border-bottom: none;
        left: 50%;
        margin-left: -20px;
        box-shadow: inset 0 2px 3px rgba(0,0,0,.5);
    }

</style>
