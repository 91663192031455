// might want to make this loadable at start
// especially if aiming to use constants or ids in the JSON output

// hard code the full list, so we can control the sorting order
const sulphur_bands = ["ULSFO", "VLSFO", "HSFO", "HFO", "ULSGO", "VLSGO", "HSGO", "MGO"]

const display_band = (b) => b == "MGO" ? "HSGO" : b == "HFO" ? "HSFO" : b 

const band_order = (x,y) => sulphur_bands.indexOf(x) - sulphur_bands.indexOf(y)

export default {
  namespaced: true,
  band_order,
  display_band, 
  sulphur_bands
}
