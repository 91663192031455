import api from '../../api';
import fuel_bands from './fuel_bands';

const appearance_map = { 1: 'Clear & Bright', 2: 'Clear', 3: 'Cloudy', 4: 'Dark', 5: 'Opaque' }

const add_fv_mm_functions = function(c) {
  c.fv_mm = function (band) { 
              let limit  = band.match('GO$') ? c.mgo_lower_limit           : c.hfo_lower_limit
              let num_dp = band.match('GO$') ? c.diesel_rounding_on_report : c.rounding_on_report
              var round_it 
              if (num_dp == 0) {
                // notice: "parseInt(-0)" is 0, ie adjusts negative 0 
                round_it = v => parseInt(Number(v.toFixed(num_dp)))
              } else {
                round_it = v => Number(v.toFixed(num_dp))
              }

              let foo = { rounding_dp: num_dp, round_fn: round_it }
              if (limit) {
                let limit_to_dp = "<" + round_it(limit).toFixed(num_dp)
                foo.data_val   = v => v < limit ? limit       : v
                foo.report_val = v => v < limit ? limit_to_dp : round_it(v).toFixed(num_dp)
              } else {
                foo.data_val   = v => v
                foo.report_val = v => round_it(v).toFixed(num_dp)
              }
 
              if (c.name == "Appearance") {
                foo.report_val = v => appearance_map[round_it(v).toFixed(0)] || '-'
              }
              return foo
            }
  return c
}


// initial state
const state = {
  data: [],
  limits: {}
}

// getters
const getters = {
  char_limits(state, _getters, rootState) {
    return function(char_id, grade = null, band = null) {
      grade = grade || rootState.options.selected_grade
      let revision = rootState.options.selected_revision

      if (char_id == 33 && revision == 4) {
        // additional filtering by fuel band
        band = band || rootState.options.selected_fuel_type
        let lims = state.limits[revision].filter(l => l.char_id == char_id && l.sulphur_band == band && l.grade == grade)
        return lims[0]

      } else if (char_id == 11) {
        // Pour Point has two cases, pick the "upper" one and use the lower limit as the lower bound
        let lims = state.limits[revision].filter( l => l.char_id == char_id && l.grade == grade)
        var upper, lower
        if (lims.length == 0) {
          upper = null
        } else if (lims.length == 1) {
          upper = lims[0]
        } else {
          if (lims.length > 2) {
            // unexpected, but ignore for now
          }
          [upper, lower] = lims[0].limit > lims[1].limit ? [lims[0], lims[1]] : [lims[1], lims[0]]
          if (lower.limit < upper.limit) {
            upper.lower_bound = lower.limit
          }
        }
        return upper

      } else {
        // return first match
        return state.limits[revision].filter( l => l.char_id == char_id && l.grade == grade)[0]
      }
    }
  },
  char_info(state) {
    return function(char_id) {
      return state.data.filter(c => c.id == char_id)[0]
    }
  },
  char_name(state) {
    return function(char_id) {
      return state.data.filter(c => c.id == char_id)[0].name
    }
  },
  y_ticks(state) {
    return function(char_id, min, max) {
      if (char_id == 36) {
        return {
          min: 0,
          max: 5,
          stepSize: 1,
          suggestedMin: 0,
          suggestedMax: 5,
          callback: function(label, index, labels) { return appearance_map[label.toString()] || ''; }
        }
      }
      else {
        return {
          beginAtZero: false,
          suggestedMin: min,
          suggestedMax: max
        }
      }
    }
  },
  chart_limits(state, getters) {
    return function(char_id, grade = null, band = null) {
      let limits = getters.char_limits(char_id, grade, band)
      if (limits == undefined) {
        return

      } else {
        // red/amber setting is same whether min_test or not
        var lines = { red:    limits.pct_95 == null ? limits.limit : limits.pct_95,
                      amber:  limits.pct_95 == null ?         null : limits.limit  }

        if (limits.special && limits.char_id == 34) {
          // V40 special case, propagate lower lim if present
          lines.bottom = limits.lower_bound
          return lines

        } else if (limits.special && limits.char_id == 33) {
          // allow sulphur through
          return lines

        } else if (limits.special) {
          // for now, skip other special cases
          return

        } else {
          // plain case
          return lines
        }
      }
    }
  }
}

// actions
const actions = {
  getData ({ commit, state }) {
    api.get('/api/v1/characteristics')
    .then(response => {
      let chars = state.data.concat(response.data)
                  .sort((x,y) => x.lr_position - y.lr_position )
                  .map(c => add_fv_mm_functions(c))
      commit('setData', chars)
    })
    api.get('/api/v1/limits/lines')
    .then(response => {
      let obj = {}
      response.data.forEach(ll => {
        obj[ll.revision] = obj[ll.revision] || []
        obj[ll.revision].push(ll)
      })
      commit('setLimits', obj)
    })
  }
}

// mutations
const mutations = {
  setData (state, data) {
    state.data = data
  },
  setLimits (state, data) {
    state.limits = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  appearance_map
}
