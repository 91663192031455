<template>
  <div class="help-wrapper">
    <div role="tablist">

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-welcome>
              Welcome to LR Fuel Finder
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-welcome" visible accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">We’ve put together a tool designed to help our clients analyse, compare, and view test data based on ports, suppliers, dates, fuel types, and of course (in this day and age) sulphur.</p>
            <p class="card-text">The design is intentionally simple, based on the premise of providing information that’s important whilst discarding information that’s not.</p>
            <p class="card-text">For example:</p>
            <ul>
              <li>Does one supplier provide a fuel more suitable for your usage than another?</li>
              <li>Has a port or supplier recently had problems with a fuel?</li>
              <li>Does a port tend to sell fuels with a higher aluminium and silicon than another port which is close by?</li>
            </ul>
            <p class="card-text">Many such questions can be answered by using this tool.</p>
            <p class="card-text">You can:</p>
            <ul>
              <li>View information from the past three months for any port / supplier combination graphically with the ability to look at the individual results by test parameter. Limits and averages are added to assist in easy visual analysis of the data.</li>
              <li>Save your port / supplier combination for later reference. For each port / supplier combination selected, you have the option to add it to a list which can contain as many variations as you want. This list is stored on our servers so the next time you log in you can easily pick back up on where you left off.</li>
              <li>Download data from the past three months for any port / supplier combination. This can be downloaded in either CSV or XLSX formats.</li>
              <li>Compare up to four port / supplier combinations and view the comparison results graphically using two different charting techniques presented side by side in order to help you home in on the specific information you’re looking for.</li>
              <li>Download a details PDF of your compared port / supplier combinations.</li>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-instructions>
              How to use LR Fuel Finder
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-instructions" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">Using LR Fuel Finder is easy!</p>
            <p class="card-text"><strong>Viewing Port Data</strong></p>
            <p class="card-text">On the main page you have a map of the world covering the screen, and on the left-hand side you have a set of filters.</p>
            <p class="card-text">As you update the information in the filters, the map will automatically update accordingly.</p>
            <ul>
              <li>Try typing in a port name and watch the icons on the map disappear, leaving just what you’re looking for available.</li>
              <li>Try changing the date range.</li>
              <li>If you click of the “Fuel type” icons, you will notice the map updating according to your selection</li>
            </ul>

            <p class="card-text">Either the above, or you can simply click on a port then click on “Port Data” and the information will be displayed.</p>
            <p class="card-text">Whilst looking at information on a port, you’ll see a filter similar to the one on the first page (the world map). This filter allows you to zoom in on your data more closely.</p>
            <p class="card-text">The table in the middle of the screen offers a quick glance at the general statistics for the selection made.</p>
            <p class="card-text">On the right-hand side is a chart which shows each data point of a specific test parameter as well as, the ISO8217 2017 limit lines, along with the average for the dataset. You can select the test parameter you would like to see the results for by clicking on the drop down above the chart. You can also add a further three charts.</p>
            <p class="card-text">Finally, if you want to keep your filter for combining with other filtered data or for later use, click on the “Add to my ports” button.</p>

            <p class="card-text"><strong>Comparing Selected Data</strong></p>
            <p class="card-text">Once you have two or more port / supplier combinations in your “my ports” list, which can be seen directly under the main filter, you can create a comparison report. Tick up to four of these, then on the “Compare selected” button and a comparison report will be shown on screen.</p>
            <p class="card-text">It’s important to note that only fuels of the same type can be compared. In other words, you won’t be able to compare an HFO and an MGO or a high Sulphur fuel with a low Sulphur fuel.</p>
            <p class="card-text">On the left-hand side the data points for all selections made are plotted in a single chart. On the right-hand side, is a kernel distribution plot or KDE plot. For those already familiar with this kind of plot, the benefits should be automatically understood. For those not familiar with the KDE plot, fear not, it’s simple to understand.</p>
            <p class="card-text">Below is an example KDE plot (example 1) comparing the viscosity results for four port / supplier combinations with an RMG380 grade. We can see a line curve for each selection. The curves on the lines are based on how many samples are grouped into a range. The more samples the higher the curve. We can see clearly in the below plot that the curves peak at around 380, meaning that most samples have a tested viscosity somewhere around 380 cSt at 50°, which is what we’d expect. However, we can also see that “Port 1” (the purple line) has quite several instances where the fuels viscosity as tested is quite a bit lower than the expected 380 cSt. And “Port 4” (the green line) has a bump at around 30 cSt at 50°C, showing some RMG380’s tested with extremely low viscosities.</p>

            <b-img fluid :src="require('./../assets/images/help/KDE.png')" />

            <p class="card-text">As you can now see, the KDE plot is a good way to quickly compare data.</p>
            <p class="card-text">The data is currently presented using ISO 8217 revision 2017. Soon ISO 8217 2005, 2010/12, and 2017 will be available for selection</p>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-sulphur>
              Sulphur and ECA Sox locations and information
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-sulphur" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">ECA coordinates will be added to the main map shortly</p>
            <p class="card-text">Information on China ECA’s can be found <a href="https://www.lrgmt.com/information_library/136" target="_blank">here</a></p>
            <p class="card-text">Information on US ECA’s can be found <a href="https://www.lrgmt.com/information_library/29" target="_blank">here</a></p>
            <p class="card-text">Information on North and Baltic sea ECA’s can be found <a href="https://www.lrgmt.com/information_library/140" target="_blank">here</a></p>

            <b-table :items="eca_data" class="table table-striped table-sm table-lrgmt" />
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-further-notes>
              Further Notes
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-further-notes" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">Some test results are excessive by nature, making them difficult to plot with standard data. To bypass this problem, we’ve given such results maximum limits. This ensures better statistical analysis and easier visualisation of the data. The adjusted parameters are as follows:</p>
            <ul>
              <li>Cetane Index:
                <ul>
                  <li>For DMA grade fuels, unobtainable results or results <= 35 will be reported as 35</li>
                  <li>For DMB grade fuels, unobtainable results or results <= 30 will be reported as 30</li>
                </ul>
              </li>
              <li>Cold Filter Plugging Point (CFPP), unobtainable results or results >= 23°C will be reported as 23°C</li>
              <li>MCR10%, unobtainable results or results >= 2.5% m/m will be reported as 2.5% m/m</li>
              <li>Total Sediment results > 25 Mins or results > 0.3% m/m will be reported as 0.3% m/m</li>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-limitations>
              Limitations
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-limitations" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">Two different fuel types cannot be compared. For example, HFO cannot be compared with MGO and High Sulphur fuels cannot be compared to low Sulphur fuels. Fuel grades of the same fuel type can be compared.</p>
            <p class="card-text">Sometimes there is a lot of data to plot. This can be hard to visualise in a small area. To view the chart more clearly click on the “See Full” button, which will open an overlay containing a larger, scrollable version.</p>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-key>
              Key
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-key" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">FOBAS advisory report status is defined by the fitness for use of the fuel, as tested, for the ship's operations, considering regulatory requirements and quality impact on, storage, handling, treatment and consumption.</p>
            <p class="card-text font-weight-bold text-success">Green: Normal</p>
            <ul>
              <li>On specification against the fuel as ordered and cleared for use.</li>
            </ul>
            <p class="card-text font-weight-bold text-warning">Amber: Caution</p>
            <ul>
              <li>On specification but above average results, requiring particular attention to storage, treatment and or handling to ensure safe usage.</li>
              <li>Marginally off specification and/or low operational risk.</li>
            </ul>
            <p class="card-text font-weight-bold text-danger">Red: Action</p>
            <ul>
              <li>Off specification and / or High risk.</li>
            </ul>
            <p class="card-text font-weight-bold">Chart Key</p>
            <ul class="list-unstyled">
              <b-media v-bind="media_props">
                <b-img slot="aside" v-bind="line_props" blank-color="#ffc107" />
                <p class="my-1">The limit line for the characteristic</p>
              </b-media>
              <b-media v-bind="media_props">
                <b-img slot="aside" v-bind="line_props" blank-color="#dc3545" />
                <p class="my-1">The limit line for the characteristic + 95% confidence (off specification)</p>
              </b-media>
              <b-media v-bind="media_props">
                <b-img slot="aside" v-bind="line_props" blank-color="#87cefa" />
                <p class="my-1">The average for the characteristic</p>
              </b-media>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-fuel-types>
              Fuel Types
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-fuel-types" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text"><span class="ft hsfo"><strong>HSFO</strong> High Sulphur Fuel Oil</span><em>A fuel oil with a Sulphur content greater than 0.50% m/m</em></p>
            <p class="card-text"><span class="ft ulsfo"><strong>ULSFO</strong> Ultra Low Sulphur Fuel Oil</span><em>A fuel oil with a Sulphur content of maximum 0.10% m/m</em></p>
            <p class="card-text"><span class="ft vlsfo"><strong>VLSFO</strong> Very Low Sulphur Fuel Oil</span><em>A fuel oil with a Sulphur content between 0.10 and 0.50% m/m</em></p>
            <p class="card-text"><span class="ft mgo"><strong>HSGO</strong> High Sulphur Gas Oil</span><em>A gas oil fuel with a Sulphur content greater than 0.50% m/m</em></p>
            <p class="card-text"><span class="ft ulsgo"><strong>ULSGO</strong> Ultra Low Sulphur Gas Oil</span><em>A gas oil fuel with a Sulphur content of maximum 0.10% m/m</em></p>
            <p class="card-text"><span class="ft vlsgo"><strong>VLSGO</strong> Very Low Sulphur Gas Oil</span><em>A gas oil fuel with a Sulphur content between 0.10 and 0.50% m/m</em></p>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header class="p-1" role="tab">
          <h4 class="mb-0">
            <b-button block variant="link" v-b-toggle.accordion-disclaimer>
              Disclaimer
              <i class="float-right fas fa-angle-down fa-lg mt-1"></i>
            </b-button>
          </h4>
        </b-card-header>
        <b-collapse id="accordion-disclaimer" accordion="help-accordion" role="tabpanel">
          <b-card-body>
            <p class="card-text">This report contains results taken from the FOBAS routine analysis data of ship drawn as bunkered samples. The data provided does not necessarily reflect the overall quality of fuels delivered at a particular port.</p>
            <p class="card-text">This data is compared against ISO 8217 6th edition, as published March 2017. See CIMAC FAQ doc on ISO 8217 for more details (available in our technical library at <a href="https://www.lrgmt.com" target="_blank">www.lrgmt.com</a>).</p>
            <p class="card-text">We fully recognise that many of the fuel deliveries covered by this report will have been specified and delivered against earlier versions of ISO 8217 or other specifications and hence will include instances where the fuel 'as bunkered' was fully within the purchaser's required specification. As has always been the case for FOBAS, it is recognised that there is an important distinction between a fuel not meeting, or meeting, a specification requirement and its suitability for use in particular cases.</p>
            <p class="card-text">Lloyd's Register Group Limited, its affiliates and subsidiaries and their respective officers, employees or agents are, individually and collectively, referred to in this clause as 'Lloyd's Register'. Lloyd's Register assumes no responsibility and shall not be liable to any person for any loss, damage or expense caused by reliance on the information or advice in this document or howsoever provided, unless that person has signed a contract with the relevant Lloyd's Register entity for the provision of this information or advice and in that case any responsibility or liability is exclusively on the terms and conditions set out in that contract.</p>
            <p class="card-text"><a href="https://stats.lrgmt.com">https://stats.lrgmt.com</a> contains confidential information intended only for Lloyd's Register clients. The information contained in <a href="https://stats.lrgmt.com">htttps://stats.lrgmt.com</a> should not be disclosed or distributed to third parties without the prior written consent of Lloyd's Register.</p>
          </b-card-body>
        </b-collapse>
      </b-card>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      eca_data: [
        {regions: 'North and Baltic Sea ECAs', before_jan_1st_2020: 'Max 0.10% m/m', after_jan_1st_2020: 'Max 0.10% m/m'},
        {regions: 'US ECA', before_jan_1st_2020: 'Max 0.10% m/m', after_jan_1st_2020: 'Max 0.10% m/m'},
        {regions: 'China ECAs', before_jan_1st_2020: 'Max 0.50% m/m', after_jan_1st_2020: 'Max 0.50% m/m'},
        {regions: 'Outside Port Limits', before_jan_1st_2020: 'Max 3.50% m/m', after_jan_1st_2020: 'Max 0.50% m/m'}
      ],
      line_props: { width: 100, height: 2, blank: true },
      media_props: { tag: "li", verticalAlign: "center" }
    }
  },
  computed: {
    ...mapState('options', ['view'])
  }
}

</script>

<style lang="scss">
  .card-header .btn-link {
    color: #fff;

    &.collapsed .fa-angle-down {
      transform: rotate(180deg);
    }

    .fa-angle-down {
      transition: transform 0.3s ease;
    }
  }
  .ft{
    display: block;
    margin-bottom: .25rem;
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    strong{
      padding: .25rem .5rem;
      color: #fff;
      width: 80px;
      display: inline-block;
      text-align: center;
      font-size: 1rem;
      margin-right: 4px;
      font-weight: 900;
    }
    em{
      font-size: .9rem;
    }
  }
  .ft.hsfo{
    background: rgba(#fbb615, .1);
    color: darken(#fbb615, 20);
    strong{
      background: #fbb615;
    }
  }
  .ft.vlsfo{
    background: rgba(#1b87c9, .1);
    color: darken(#1b87c9, 20);
    strong {
      background: #1b87c9;
    }
  }
  .ft.ulsfo{
    background: rgba(#0db14b, .1);
    color: darken(#0db14b, 20);
    strong {
      background: #0db14b;
    }
  }
  .ft.mgo{
    background: rgba(#6461ab, .1);
    color: darken(#6461ab, 20);
    strong {
      background: #6461ab;
    }
  }
  .ft.ulsgo{
    background: rgba(#ec2490, .1);
    color: darken(#ec2490, 20);
    strong {
      background: #ec2490;
    }
  }
  .ft.vlsgo{
    background: rgba(#f26922, .1);
    color: darken(#f26922, 20);
    strong {
      background: #f26922;
    }
  }
</style>
