<script>
  import { Line, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins
  export default {
    extends: Line,
    mixins: [reactiveProp],
    props: {
      minValue: {
        type: Number
      },
      maxValue: {
        type: Number
      }
    },
    data () {
      return {
        options: {
          scales: {
            yAxes: [{
              scaleLabel: {
                labelString: '% of results',
                display: true
              },
              ticks: {
                beginAtZero: true,
                minRotaton: 90,
                maxRotation:90
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              type: 'linear',
              display: false,
              ticks: {
                maxTicksLimit: 20,
                min: 0,
                max: 20
              }
            },{
              type: 'linear',
              gridLines: {
                display: false
              },
              ticks: {
                min: this.minValue,
                max: this.maxValue,
                maxTicksLimit: 10
              }
            }],
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: false
          },
          spanGaps: true
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>
