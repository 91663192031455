import moment from 'moment'
import api from '../../api'

// initial state
const state = {
  dates: {
    from: moment().subtract(3, 'months').startOf('day'),
    to  : moment().startOf('day')
  },
  selected_band_and_grade: null,
  selected_grade: null,
  selected_fuel_type: null,
  selected_port: null,
  selected_supplier: null,
  selected_revision: 4,
  comparison_ports: [],
  selected_comparison_ports: [],
  view: 'globe',
  loading: true,
  banner_seen: false,
  map_center: null,
  map_zoom: null,
  pdf_alert: {
    class: null,
    text: null,
    seconds: 0
  }

}

// getters
const getters = {
}

// actions
const actions = {
  update_band_and_grade({ commit, dispatch, rootGetters }, option) {
    let band,grade
    if (option) {
      [band,grade] = option.split(";")
    }
    //dispatch('reset_fuel_options', true)
    commit('update_grade', true)
    commit('update_band_and_grade', option)
    commit('update_fuel_type', band)
    commit('update_grade', grade)
    dispatch('reset_supplier_check')
    dispatch('check_suppliers')
    dispatch('update_characteristics')
  },
  update_supplier({ commit, dispatch }, sn) {
    commit('update_supplier', sn)
    dispatch('update_characteristics')
  },
  check_suppliers({rootGetters, commit}) {
    if (rootGetters['single_port/available_suppliers'].length == 1) {
      commit('update_supplier', rootGetters['single_port/available_suppliers'][0].id)
    }
  },
  reset_supplier_check({ commit, rootGetters, state }) {
    // if supplier is set, check to see if compatible with band and grade
    // if supplier isn't compatible, set to null
    if (!rootGetters['single_port/available_suppliers'].map( s => s.id ).includes(state.selected_supplier)) {
      commit('update_supplier', null)
    }
  },
  update_characteristics({ dispatch }) {
    dispatch('single_port/updateCharacteristics', {}, {root: true})
    dispatch('comparison/updateCharacteristics', {}, {root: true})
  },
  view_single_port({ commit, dispatch }, port_id) {
    commit('set_view', port_id == undefined ? 'globe' : 'port')
    commit('update_fuel_type', null)
    dispatch('set_single_port', port_id)
  },
  view_selection({ commit }, port) {
    commit('set_single_port', port.port_id)
    commit('update_fuel_type', port.fuel_type)
    commit('update_grade', port.grade)
    commit('update_supplier', port.supplier)
    commit('set_view', 'port')
  },
  set_single_port({ commit }, port_id) {
    commit('set_single_port', port_id)
  },
  add_to_comparison({ commit, dispatch, rootGetters, state }) {
    let data = {
      fuel_type: state.selected_fuel_type,
      grade: state.selected_grade,
      port_id: state.selected_port,
      port_name: rootGetters['ports/port_by_id'](state.selected_port)[0].nm,
      supplier: state.selected_supplier,
      samples: true
    }
    let matches = state.comparison_ports.filter(port => {
      return (
        port.port_id   == state.selected_port &&
        port.grade     == state.selected_grade &&
        port.supplier  == state.selected_supplier &&
        port.fuel_type == state.selected_fuel_type
      )
    })
    if (matches.length == 0) {
      commit('add_to_comparison', data)
    }
    dispatch('save_ports')
  },
  remove_from_comparison({ commit, state, dispatch }, index) {
    let port_data = state.comparison_ports[index]
    let selected_index = state.selected_comparison_ports.findIndex(port => port.id == port_data.id && port.grade == port_data.grade && port.supplier == port_data.supplier)
    commit('remove_from_comparison', index)
    commit('remove_from_selected', selected_index)
    dispatch('save_ports')
  },
  save_ports({ state }) {
    api.post('/api/v1/user', {
      ports: state.comparison_ports
    })
  },
  check_selection_samples({ state, commit }, ports) {
    api.get('api/v1/samples/comparison', {params: {
      ports: state.comparison_ports,
      from:  state.dates.from,
      to:    state.dates.to,
      limit: true
    }})
    .then(response => {
      let saved_ports = response.data.map( d => {
        let port = d.data
        port.samples = !(d.samples == undefined)
        if (typeof d.data.supplier == 'string') port.supplier = null
        return port
      })
      commit('set_saved_ports', saved_ports)
      // console.log("saved ports", response)
    })
  },
  set_date({ commit, dispatch }, payload) {
    commit('set_date', payload)
    dispatch('ports/getPortData', {}, {root: true})
    dispatch('check_selection_samples')
  },
  set_view({ commit }, view) {
    commit('set_view', view)
  },
  set_selected_comparison_ports({commit}, ports) {
    commit('set_selected_comparison_ports', ports)
  },
  set_loading({ commit }, loading) {
    commit('set_loading', loading)
  },
  set_banner_seen({ commit }, seen) {
    commit('set_banner_seen', seen)
  },
  getSavedPorts({ commit, dispatch }) {
    api.get('api/v1/user').
    then(response => {
      commit('set_saved_ports', response.data.ports)
      dispatch('auth/set_user', response.data.user, { root: true })
      dispatch('check_selection_samples')
    })
  },
  set_map_options({commit}, options) {
    commit('set_map_zoom', options.zoom)
    commit('set_map_center', options.center)
  },
  set_pdf_alert({commit}, obj) {
    commit('set_pdf_alert', obj)
  }
}

// mutations
const mutations = {
  update_band_and_grade(store, opt) {
    store.selected_band_and_grade = opt
  },
  update_fuel_type(store, id) {
    store.selected_fuel_type = id
  },
  update_grade(store, grade) {
    store.selected_grade = grade
  },
  update_supplier(store, supplier) {
    store.selected_supplier = supplier
  },
  set_single_port(store, port_id) {
    store.selected_port = port_id
  },
  show_port_data(store, value) {
    store.show_port_data = value
  },
  add_to_comparison(store, port_id) {
    store.comparison_ports.push(port_id)
  },
  remove_from_comparison(store, index) {
    let array = store.comparison_ports
    if (index !== -1) {
      array.splice(index, 1)
    }
    store.comparison_ports = array
  },
  remove_from_selected(store, index) {
    let array = store.selected_comparison_ports
    if (index !== -1) {
      array.splice(index, 1)
    }
    store.selected_comparison_ports = array
  },
  set_date(store, payload) {
    store.dates[payload.type] = payload.value
  },
  set_view(store, view) {
    store.view = view
  },
  set_loading(store, loading) {
    store.loading = loading
  },
  set_banner_seen(store, seen) {
    store.banner_seen = seen
  },
  set_selected_comparison_ports(store, ports) {
    store.selected_comparison_ports = ports
  },
  set_saved_ports(store, ports) {
    store.comparison_ports = ports
  },
  set_map_zoom(store, zoom) {
    store.map_zoom = zoom
  },
  set_map_center(store, center) {
    store.map_center = center
  },
  set_pdf_alert(store, obj) {
    store.pdf_alert = obj
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
