<script>
  import { Bar, mixins } from 'vue-chartjs'
  import horizontalLine from '../assets/js/chartjs-line-plugin'
  const { reactiveProp } = mixins
  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      showLegend: {
        default: false,
        type: Boolean
      },
      lines: {
        type: Array
      },
      y_ticks: {
        type: Object,
      },
      y_label: {
        type: String,
      },
      animations: {
        type: Object
      }
    },
    data () {
      return {
        options: {
          horizontalLine: this.lines,
          scales: {
            yAxes: [{
              ticks: this.y_ticks,
              scaleLabel: { labelString: this.y_label, display: true },
              gridLines: {
                display: true
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              },
            }],
          },
          legend: {
            display: this.showLegend
          },
          responsive: true,
          maintainAspectRatio: false,
          animation: this.animations
        }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    },
  }
</script>
