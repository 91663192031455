import api from '../../api';

// initial state
const state = {
  data: {}
}

// getters
const getters = {
  supplier_name(state) {
    return function(supplier_id) {
      return state.data[supplier_id] || 'All Suppliers'
    }
  }
}

// actions
const actions = {
  getData ({ commit, state }) {
    api.get('/api/v1/suppliers')
    .then(response => {
      commit('setData', response.data)
    })
  }
}

// mutations
const mutations = {
  setData (state, data) {
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
